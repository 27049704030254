import {AppName} from '@hconnect/common/hproduce'
import {TimeZone} from '@hconnect/uikit'
import {
  defaultQuickSelectionItems,
  timeRangeParamToDateRange
} from '@hconnect/uikit/src/lib2/components/simpleDatePicker/dateRangePickerHelpers'
import {getI18n} from 'react-i18next'

import {TaskFilterDto, TasksFiltersState, TaskSource, TasksSortBy, TasksTableKey} from '../types'

const SERVER_APP_NAME_MAP: Record<TaskSource, AppName> = {
  [TaskSource.COCKPIT]: 'Cockpit',
  [TaskSource.EASY_POM]: 'easyPOM'
}

export const mapToAppName = (source: TaskSource): AppName =>
  SERVER_APP_NAME_MAP[source] ?? 'HProduce'

export const mapTasksFiltersParams = (
  timezone: TimeZone,
  filters?: TasksFiltersState
): TaskFilterDto | undefined => {
  if (!filters) {
    return undefined
  }

  const {
    plant,
    mainEquipment,
    equipment,
    pomActionSources,
    pomSections,
    pomTopics,
    pomQuestions,
    pomQuestionCategories,
    dueDateRange,
    ...restFilters
  } = filters

  const {from: dueDateFrom, to: dueDateTo} = mapTimeRangeParam(timezone, dueDateRange)

  return {
    ...restFilters,
    plantIds: plant ? [plant] : undefined,
    mainEquipmentId: mainEquipment,
    equipmentId: equipment,
    actionSources: pomActionSources,
    sectionIds: pomSections,
    topicIds: pomTopics,
    questionIds: pomQuestions,
    questionDifficulties: pomQuestionCategories,
    dueDateFrom,
    dueDateTo
  }
}

const mapTimeRangeParam = (
  timezone: TimeZone,
  timeRange?: string
): {from?: string; to?: string} => {
  const selectionItems = defaultQuickSelectionItems(getI18n().t, timezone)
  const [from, to] = timeRangeParamToDateRange({timeRange, selectionItems})

  return {from: from?.toISOString(), to: to?.toISOString()}
}

const tasksSortByMap: Partial<Record<TasksTableKey, TasksSortBy>> = {
  [TasksTableKey.TITLE]: TasksSortBy.TITLE,
  [TasksTableKey.DUE_DATE]: TasksSortBy.DUE_DATE,
  [TasksTableKey.CREATED]: TasksSortBy.CREATED_AT,
  [TasksTableKey.STATUS]: TasksSortBy.STATUS,
  [TasksTableKey.ASSIGNEE]: TasksSortBy.ASSIGNEE
}

const tasksTableKeyMap: Partial<Record<TasksSortBy, TasksTableKey>> = {
  [TasksSortBy.TITLE]: TasksTableKey.TITLE,
  [TasksSortBy.DUE_DATE]: TasksTableKey.DUE_DATE,
  [TasksSortBy.CREATED_AT]: TasksTableKey.CREATED,
  [TasksSortBy.STATUS]: TasksTableKey.STATUS,
  [TasksSortBy.ASSIGNEE]: TasksTableKey.ASSIGNEE
}

export const mapTasksTableKeyToSortBy = (tableKey?: TasksTableKey): TasksSortBy | undefined => {
  return tasksSortByMap[tableKey as TasksTableKey]
}

export const mapTasksSortByToTableKey = (sortBy?: TasksSortBy): TasksTableKey | undefined => {
  return tasksTableKeyMap[sortBy as TasksSortBy]
}
