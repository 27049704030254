import {ActionSource, ACTION_SOURCES} from '@hconnect/common/types'
import {getTranslationKey} from '@hconnect/common/utils'
import {useTranslation} from 'react-i18next'

import {FilterMultiSelect, FilterMultiSelectProps} from '../../../shared/components'
import {FILTER_VALUE_SEPARATOR} from '../../../shared/consts'

export const PomActionSourceFilter = <TName extends string>({
  translationPrefix,
  ...props
}: Omit<FilterMultiSelectProps<ActionSource, TName>, 'options'>) => {
  const {t} = useTranslation()
  const options = Object.values(ACTION_SOURCES).map((actionSource) => ({
    value: actionSource,
    label: t(getTranslationKey(`pomAction.actionSource.${actionSource}`, translationPrefix))
  }))

  return (
    <FilterMultiSelect
      {...props}
      translationPrefix={translationPrefix}
      options={options}
      renderValue={(value) =>
        value
          .map((actionSource) =>
            t(getTranslationKey(`pomAction.actionSource.${actionSource}`, translationPrefix))
          )
          .join(FILTER_VALUE_SEPARATOR)
      }
    />
  )
}
