import {PlantRunningTimesDto as PlantRunningTimes} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {stringify} from 'query-string'
import {useQuery, UseQueryOptions} from 'react-query'

import {ApiPath} from '../../shared/consts'
import {useAxios} from '../../shared/hooks/useApi'
import {TimeRange} from '../types'

const QueryKey = 'plants-running-times'

export const usePlantsRunningTimes = (
  timeRange: TimeRange,
  plantIds: string[],
  options?: UseQueryOptions<PlantRunningTimes[], AxiosError>
) => {
  const axiosInstance = useAxios()

  return useQuery<PlantRunningTimes[], AxiosError>(
    [QueryKey, timeRange, plantIds],
    async () => {
      const response = await axiosInstance.get<PlantRunningTimes[]>(
        `${ApiPath.RUNNING_TIMES_ORCHESTRATOR}/running-times`,
        {
          params: {
            from: timeRange.startDate,
            to: timeRange.endDate,
            plantIds
          },
          paramsSerializer: (params: string[]) => {
            return stringify(params, {arrayFormat: 'none'})
          }
        }
      )
      return response.data
    },
    options
  )
}
