import {TasksFilterKey, TasksQuickFilterKeys, TasksSortOptionsKeys} from '../consts'
import {TasksFiltersState, TasksPageQueryParams, TasksSortingOptions} from '../types'
import {extractValidProperties} from '../utils'

import {useTasksPageQueryParams} from './useTasksPageQueryParams'

type UseTasksPageFiltersReturn = {
  filters: TasksFiltersState
  sorting: TasksSortingOptions
  updateFilters: (newFilters: TasksFiltersState) => void
  areFiltersApplied: boolean
}

const tasksFiltersKeys = Object.values(TasksFilterKey)
const tasksQuickFiltersKeys = Object.values(TasksQuickFilterKeys)
const tasksSortingKeys = Object.values(TasksSortOptionsKeys)

const isFilterApplied = (filters: Partial<TasksPageQueryParams>) =>
  Object.values(filters).some((value) => value !== undefined)

export const useTasksPageFilters = (): UseTasksPageFiltersReturn => {
  const [params, {updateParams}] = useTasksPageQueryParams()
  const filters = extractValidProperties(params, tasksFiltersKeys)
  const quickFilters = extractValidProperties(params, tasksQuickFiltersKeys)
  const sorting = extractValidProperties(params, tasksSortingKeys)

  const areFiltersApplied = isFilterApplied(filters) || isFilterApplied(quickFilters)

  return {
    filters,
    sorting,
    updateFilters: updateParams,
    areFiltersApplied
  }
}
