import {ChecklistForm} from '../components/ChecklistForm'
import {DataContentWrapper} from '../components/DataContentWrapper'
import {useChecklist, useChecklistUpdate} from '../hooks/api'
import {ChecklistStatus} from '../types'

type Props = {
  checklistId: string
  plantId: string
  backToTargetLabel: string
  onCancel: () => void
}

export const ChecklistContainer = ({checklistId, plantId, backToTargetLabel, onCancel}: Props) => {
  const {
    data: checklist,
    isLoading,
    isError,
    isRefetching,
    refetch
  } = useChecklist({id: checklistId, plantId})
  const updateMutation = useChecklistUpdate({id: checklistId, plantId})
  const isReadOnly = checklist?.status === ChecklistStatus.DONE

  return (
    <DataContentWrapper
      isLoading={isLoading}
      isError={isError}
      isRefetching={isRefetching}
      data={checklist}
      retryFunction={() => void refetch()}
    >
      <ChecklistForm
        checklist={checklist!}
        readOnly={isReadOnly}
        updateMutation={updateMutation}
        onCancel={onCancel}
        backToTargetLabel={backToTargetLabel}
      />
    </DataContentWrapper>
  )
}
