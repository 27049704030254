import {CardBox} from '@hconnect/uikit/src/lib2'
import {CalendarTodayOutlined} from '@mui/icons-material'
import {Box, Chip, SxProps, Theme, Typography} from '@mui/material'
import moment from 'moment'
import {useTranslation} from 'react-i18next'

import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {DataContentWrapper, OperationHoursList} from '../components'
import {useOperationHours} from '../hooks/api'

type Props = {
  plantId: string
  onEquipmentClick: (equipmentId: string) => void
  cardBoxSx?: SxProps<Theme>
}

const cardBoxPx = {xs: 2, md: 3}

export const OperationHoursContainer = ({plantId, onEquipmentClick, cardBoxSx}: Props) => {
  const {t} = useTranslation()
  const {hrocPrefix, performancePrefix} = useTranslationPrefix()
  const timezone = usePlantTimezone()
  // set seconds and milliseconds to 0 to optimize the number of OperationHours rerenders
  const currentTime = moment.utc().tz(timezone).set({second: 0, millisecond: 0})
  const startOfYesterday = moment.utc().tz(timezone).subtract(1, 'day').startOf('day')
  const endOfToday = moment.utc().tz(timezone).endOf('day')

  const {
    data: runningTimePerformances,
    isLoading,
    isError,
    isRefetching,
    refetch
  } = useOperationHours({
    plantId,
    from: startOfYesterday,
    to: endOfToday
  })

  return (
    <CardBox
      sx={{
        ...cardBoxSx,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        px: 0,
        pl: cardBoxPx
      }}
      data-test-id="operation-hours-card"
    >
      <Box
        sx={{
          display: 'flex',
          pr: cardBoxPx
        }}
      >
        <Box flex={1}>
          <Typography variant="h3" mb={2}>
            {t(`${hrocPrefix}.operationHours.cardTitle`)}
          </Typography>
        </Box>
        <Chip
          icon={<CalendarTodayOutlined />}
          label={t(`${hrocPrefix}.label.fromYesterday`)}
          size="small"
          sx={{mx: 1}}
        />
      </Box>
      <DataContentWrapper
        isLoading={isLoading}
        isError={isError}
        isRefetching={isRefetching}
        data={runningTimePerformances}
        retryFunction={() => void refetch()}
        progressSx={{color: 'primary.main'}}
      >
        <Box sx={{flex: 1, overflowY: 'auto', pr: cardBoxPx}}>
          <OperationHoursList
            runningTimePerformances={runningTimePerformances}
            from={startOfYesterday.toISOString()}
            to={currentTime.toISOString()}
            timezone={timezone}
            translationPrefix={performancePrefix}
            onEquipmentClick={onEquipmentClick}
          />
        </Box>
      </DataContentWrapper>
    </CardBox>
  )
}
