import {CardBox} from '@hconnect/uikit/src/lib2'
import {CalendarTodayOutlined, ShowChart} from '@mui/icons-material'
import {Box, Chip, Typography} from '@mui/material'
import moment from 'moment'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {CardLinkWrapper, DataContentWrapper, PlantStatusKpis} from '../components'
import {cardBoxSizes} from '../consts'
import {useDailyPerformanceKpis, useEquipmentPerformancesMonthly} from '../hooks/api'
import {mapToProcessEngineerMonthlyKpiTrends} from '../mappers'
import {MonthlyKpiSummaryDto} from '../types'
import {formatKpiValue, getProcessEngineerPerformancePagePath} from '../utils'

const getKpiUnitByKey = (
  key: keyof Omit<MonthlyKpiSummaryDto, 'year' | 'month'>,
  data?: Omit<MonthlyKpiSummaryDto, 'year' | 'month'>[]
): string | undefined => {
  const item = data?.find((item) => item[key]?.unit)

  return item?.[key]?.unit
}

type Props = {
  plantId: string
}

const AGGREGATION_MONTHS_COUNT = 6

export const ProcessPlantStatusKpisContainer: React.FC<Props> = ({plantId}) => {
  const {t, i18n} = useTranslation()
  const language = i18n.language
  const {hrocPrefix} = useTranslationPrefix()
  const timezone = usePlantTimezone()
  const to = moment.utc().tz(timezone).startOf('month')
  const from = to.clone().subtract(AGGREGATION_MONTHS_COUNT, 'months')

  const {
    data: monthlyAggregatedKpis,
    isLoading: areMonthlyKpiTrendsLoading,
    isError: isMontlyKpisError,
    isRefetching: isMonthlyKpisRefetching,
    refetch: refetchMonthlyKpis
  } = useEquipmentPerformancesMonthly({plantId, from, to})
  const monthlyKpiTrends = mapToProcessEngineerMonthlyKpiTrends(monthlyAggregatedKpis)

  const startOfYesterday = moment.utc().tz(timezone).subtract(1, 'days').startOf('day')
  const endOfYesterday = startOfYesterday.clone().endOf('day')
  const {
    data: yesterdayKpis,
    isLoading: isYesterdayKpisLoading,
    isError: isYesterdayKpisError,
    isRefetching: isYesterdayKpisRefetching,
    refetch: refetchYesterdayKpis
  } = useDailyPerformanceKpis({plantId, from: startOfYesterday, to: endOfYesterday})

  const linkPath = getProcessEngineerPerformancePagePath(plantId, 'yesterday')

  const kpiItems = useMemo(
    () => [
      {
        key: 'clinker-production',
        label: t(`${hrocPrefix}.plantStatus.label.clinkerProduction`),
        value: formatKpiValue(yesterdayKpis?.clinkerProduction?.value, language),
        unit: yesterdayKpis?.clinkerProduction?.unit,
        kpiBreachSeverity: yesterdayKpis?.clinkerProduction?.kpiBreachSeverity,
        monthlyTrendData: monthlyKpiTrends?.clinkerProduction,
        monthlyTrendDataUnit: getKpiUnitByKey('clinkerProduction', monthlyAggregatedKpis)
      },
      {
        key: 'cement-production',
        label: t(`${hrocPrefix}.plantStatus.label.cementProduction`),
        value: formatKpiValue(yesterdayKpis?.cementProduction?.value, language),
        unit: yesterdayKpis?.cementProduction?.unit,
        kpiBreachSeverity: yesterdayKpis?.cementProduction?.kpiBreachSeverity,
        monthlyTrendData: monthlyKpiTrends?.cementProduction,
        monthlyTrendDataUnit: getKpiUnitByKey('cementProduction', monthlyAggregatedKpis)
      },
      {
        key: 'kiln-heat-consumption',
        label: t(`${hrocPrefix}.plantStatus.label.kilnHeatConsumption`),
        monthlyTrendData: monthlyKpiTrends?.kilnHeatConsumption,
        monthlyTrendDataUnit: getKpiUnitByKey('kilnHeatConsumption', monthlyAggregatedKpis)
      },
      {
        key: 'raw-mill-sp-power-consumption',
        label: t(`${hrocPrefix}.plantStatus.label.rawMillsSpPowerConsumption`),
        monthlyTrendData: monthlyKpiTrends?.rawMillsPowerConsumption,
        monthlyTrendDataUnit: getKpiUnitByKey('rawMillsPowerConsumption', monthlyAggregatedKpis)
      },
      {
        key: 'kiln-sp-power-consumption',
        label: t(`${hrocPrefix}.plantStatus.label.kilnPowerConsumption`),
        monthlyTrendData: monthlyKpiTrends?.kilnPowerConsumption,
        monthlyTrendDataUnit: getKpiUnitByKey('kilnPowerConsumption', monthlyAggregatedKpis)
      },
      {
        key: 'cement-mills-sp-power-consumption',
        label: t(`${hrocPrefix}.plantStatus.label.cementMillsPowerConsumption`),
        monthlyTrendData: monthlyKpiTrends?.cementMillsPowerConsumption,
        monthlyTrendDataUnit: getKpiUnitByKey('cementMillsPowerConsumption', monthlyAggregatedKpis)
      },
      {
        key: 'raw-mills-production-rate',
        label: t(`${hrocPrefix}.plantStatus.label.rawMillsProductionRate`),
        monthlyTrendData: monthlyKpiTrends?.rawMillsProductionRate,
        monthlyTrendDataUnit: getKpiUnitByKey('rawMillsProductionRate', monthlyAggregatedKpis)
      },
      {
        key: 'clinker-production-rate',
        label: t(`${hrocPrefix}.plantStatus.label.clinkerProductionRate`),
        monthlyTrendData: monthlyKpiTrends?.clinkerProductionRate,
        monthlyTrendDataUnit: getKpiUnitByKey('clinkerProductionRate', monthlyAggregatedKpis)
      },
      {
        key: 'cement-mills-production-rate',
        label: t(`${hrocPrefix}.plantStatus.label.cementMillsProductionRate`),
        monthlyTrendData: monthlyKpiTrends?.cementMillsProductionRate,
        monthlyTrendDataUnit: getKpiUnitByKey('cementMillsProductionRate', monthlyAggregatedKpis)
      }
    ],
    [yesterdayKpis, monthlyKpiTrends, monthlyAggregatedKpis, t, language, hrocPrefix]
  )

  return (
    <CardLinkWrapper
      href={linkPath}
      data-test-id="plant-status-kpis-card-link-to-performance-plant-status"
    >
      <CardBox sx={cardBoxSizes['L']} data-test-id="plant-status-kpis-card">
        <Box display="flex" flexDirection="row">
          <Box flex={1}>
            <Typography variant="h3" mb={2}>
              {t(`${hrocPrefix}.plantStatus.cardTitle`)}
            </Typography>
          </Box>
          <Chip
            icon={<CalendarTodayOutlined />}
            label={t(`${hrocPrefix}.label.yesterday`)}
            size="small"
            sx={{mx: 1}}
          />
          <Chip
            icon={<ShowChart />}
            label={t(`${hrocPrefix}.label.monthsCount`, {count: AGGREGATION_MONTHS_COUNT})}
            size="small"
            sx={{mx: 1}}
          />
        </Box>
        <DataContentWrapper
          isLoading={areMonthlyKpiTrendsLoading && isYesterdayKpisLoading}
          isError={isMontlyKpisError || isYesterdayKpisError}
          isRefetching={isMonthlyKpisRefetching || isYesterdayKpisRefetching}
          data={yesterdayKpis || monthlyKpiTrends}
          retryFunction={() => {
            if (isMontlyKpisError) {
              void refetchMonthlyKpis()
            }
            if (isYesterdayKpisError) {
              void refetchYesterdayKpis()
            }
          }}
          progressSx={{color: 'primary.main'}}
        >
          <PlantStatusKpis
            kpiItems={kpiItems}
            areMonthlyKpiTrendsLoading={areMonthlyKpiTrendsLoading}
            gridColumns={10}
            gridItemXs={2}
          />
        </DataContentWrapper>
      </CardBox>
    </CardLinkWrapper>
  )
}
