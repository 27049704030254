export type ChipsFilterOption<TOptionValue extends string> = {
  value: TOptionValue
  label: string
}

export type FilterConfig<TKeyValue extends string> = {
  value: TKeyValue
  label: string
  filter: React.ReactNode
  disabled?: boolean
  tooltipTitle?: React.ReactNode
}
