import {AnalysisListType} from '@hconnect/common/types'
import {getMaintainAnalysisListUrl} from '@hconnect/common/utils'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {CalendarTodayOutlined} from '@mui/icons-material'
import {Box, Chip, SxProps, Theme, Typography} from '@mui/material'
import moment from 'moment'
import {useTranslation} from 'react-i18next'

import {useRcfaAvailability} from '../../performanceDashboard/hooks/useRcfaAvailability'
import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {CardLinkWrapper, DataContentWrapper, RcfaSummary} from '../components'

type Props = {
  plantId: string
  cardBoxSx?: SxProps<Theme>
  placeholderWrapperSx?: SxProps<Theme>
}

export const RcfaContainer = ({plantId, cardBoxSx, placeholderWrapperSx}: Props) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const timezone = usePlantTimezone()
  const startOfToday = moment.utc().tz(timezone).startOf('day')
  const thirtyDaysAgo = startOfToday.clone().subtract(30, 'day')
  const endOfToday = startOfToday.clone().endOf('day')
  const timeRange = {
    startDate: thirtyDaysAgo.toISOString(),
    endDate: endOfToday.toISOString()
  }

  const {data, isLoading, isError, isRefetching, refetch} = useRcfaAvailability(plantId, timeRange)

  const linkPath = getMaintainAnalysisListUrl(plantId, AnalysisListType.MyPlant)

  return (
    <CardLinkWrapper href={linkPath} data-test-id="rcfa-summary-card-link-to-maintain">
      <CardBox sx={cardBoxSx} data-test-id="rcfa-summary-card">
        <Box display="flex" flexDirection="row">
          <Box flex={1}>
            <Typography variant="h3" mb={2}>
              {t(`${hrocPrefix}.rcfaSummary.cardTitle`)}
            </Typography>
          </Box>
          <Chip
            icon={<CalendarTodayOutlined />}
            label={t(`${hrocPrefix}.label.last30days`)}
            size="small"
            sx={{mx: 1}}
          />
        </Box>
        <DataContentWrapper
          isLoading={isLoading}
          isError={isError}
          isRefetching={isRefetching}
          data={data}
          retryFunction={() => void refetch()}
          progressSx={{color: 'primary.main'}}
          placeholderWrapperSx={placeholderWrapperSx}
        >
          {data && <RcfaSummary {...data} />}
        </DataContentWrapper>
      </CardBox>
    </CardLinkWrapper>
  )
}
