import {Box, Grid} from '@mui/material'
import {isEmpty} from 'lodash'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate, useParams} from 'react-router'

import {checkFeatureFlag} from '../../shared/helpers/featureFlagHelpers'
import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {DowntimeFilters} from '../components/downtime/filters/Filters'
import {StoppageStatsTable} from '../components/downtime/StoppageStatsTable'
import {TopIssues} from '../components/downtime/TopIssues'
import {KpiBox} from '../components/KpiBox'
import {DOWNTIME_DETAILS_FILTER_OPTIONS} from '../consts'
import {
  getFailureKindsFromUrl,
  DowntimeAnalyticsEventName,
  useTrackDowntimeAnalyticsEvent
} from '../helpers'
import {useDowntimeStoppageDetailsStates} from '../hooks/useDowntimeStoppageDetailsStates'
import {useEquipmentProductionTime} from '../hooks/useEquipmentProductionTime'
import {useSortedStoppages} from '../hooks/useSortedStoppages'
import {useStoppageCodeStats} from '../hooks/useStoppageCodeStats'
import {usePlantTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {
  PLANT_DOWNTIME_STOPPAGES,
  PLANT_DOWNTIME_STOPPAGES_DETAILS,
  PLANT_DOWNTIME_STOPPAGES_DETAILS_FOR_CODE
} from '../routing'
import {AggregatedStoppageCodeStatistics, IssueGroupingKey, StoppageCode} from '../types'

import {StatusPageHeader} from './StatusPageHeader'

type PathParameter = {
  plantId: string
  groupId: string
  groupType: IssueGroupingKey
}

type EquipmentStoppageDetailsProps = {
  stoppageConfig?: StoppageCode[]
}

const getTheTitle = ({
  groupKey,
  groupId,
  stoppageStatsData = {}
}: {
  groupKey: IssueGroupingKey
  groupId: string
  stoppageStatsData?: AggregatedStoppageCodeStatistics
}) => {
  if (isEmpty(stoppageStatsData)) return ''
  let title: string | undefined
  Object.keys(stoppageStatsData).forEach((stoppageCode) => {
    const stoppageCodeStats = stoppageStatsData[stoppageCode]

    if (groupKey === IssueGroupingKey.EQUIPMENT_NUMBER) {
      title = stoppageCodeStats.equipments.find((equipment) => equipment.id === groupId)?.text
    }

    if (groupKey === IssueGroupingKey.MAIN_EQUIPMENT) {
      title = stoppageCodeStats.mainEquipments.find((mainEquipment) => mainEquipment.id === groupId)
        ?.text
    }
  })
  return title || groupId
}

export const EquipmentStoppageDetails: React.FC<EquipmentStoppageDetailsProps> = ({
  stoppageConfig
}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const navigate = useNavigate()
  const {plantId, groupType, groupId} = useParams<PathParameter>()
  if (!plantId || !groupId || !groupType) throw new Error('Missing plantId or stoppage group prop')
  const timeRange = usePlantTimeRange()
  const trackAnalyticsEvent = useTrackDowntimeAnalyticsEvent()
  const timezone = usePlantTimezone()

  const {
    mainEquipments,
    setMainEquipments,
    equipmentNumbers,
    setEquipmentNumbers,
    failureStoppage,
    setFailureStoppage,
    selectedStoppageCodes,
    setSelectedStoppageCodes
  } = useDowntimeStoppageDetailsStates({groupId, groupType})

  const {
    topIssuesSortingKey,
    setTopIssuesSortingKey,
    topIssuesGroupingKey,
    sortedStoppages,
    aggregatedStoppagesQuery
  } = useSortedStoppages({
    plantId,
    groupBy: IssueGroupingKey.STOPPAGE_CODE,
    timeRange,
    selectedMainEquipments: mainEquipments?.map((eq) => eq.id),
    selectedStoppages: getFailureKindsFromUrl(failureStoppage),
    selectedEquipmentNumbers: equipmentNumbers?.map((eq) => eq.id)
  })

  const stoppageCodeStatsQuery = useStoppageCodeStats(plantId, {
    ...timeRange,
    // NOTE: for equipments/mainEquipments page, groupBy is forced to STOPPAGE_CODE
    groupBy: IssueGroupingKey.STOPPAGE_CODE,
    ...(mainEquipments && {selectedMainEquipments: mainEquipments?.map((eq) => eq.id)}),
    ...(equipmentNumbers && {selectedEquipments: equipmentNumbers?.map((eq) => eq.id)}),
    ...(failureStoppage && {selectedStoppages: getFailureKindsFromUrl(failureStoppage)}),
    ...(selectedStoppageCodes && {selectedStoppageCodes})
  })

  // Enable this with follow-up after HCP-70332
  // It requires UPM so that running times equipment can be mapped to the equipment process stages
  const isDowntimeProductionTimeEnabled = checkFeatureFlag('enableDowntimeProductionTime')
  const {data: equipmentProductionTime} = useEquipmentProductionTime(
    plantId,
    {
      timeRange,
      mainEquipmentNumbers: mainEquipments?.map((eq) => eq.id),
      stoppageCodes: selectedStoppageCodes,
      stoppageKinds: getFailureKindsFromUrl(failureStoppage),
      equipmentNumbers: equipmentNumbers?.map((eq) => eq.id)
    },
    {
      enabled: isDowntimeProductionTimeEnabled
    }
  )
  const equipmentProductionData = useMemo(() => {
    return equipmentProductionTime?.find((eq) => eq.equipmentMatchingId === groupId)
  }, [equipmentProductionTime, groupId])

  const title = getTheTitle({
    groupId,
    groupKey: groupType,
    stoppageStatsData: stoppageCodeStatsQuery.data
  })

  return (
    <>
      <StatusPageHeader
        title={title}
        backButtonProps={{
          target: generatePath(PLANT_DOWNTIME_STOPPAGES, {plantId}),
          targetName: t(`${performancePrefix}.downtime.actions.backToStoppages`)
        }}
        filterComponent={
          <DowntimeFilters
            failureState={{
              failureStoppage: getFailureKindsFromUrl(failureStoppage),
              setFailureStoppage: (failures) => setFailureStoppage(failures?.join(','))
            }}
            mainEquipmentState={{mainEquipments, setMainEquipments}}
            stoppageCodesState={{
              stoppageCodes: selectedStoppageCodes,
              setStoppageCodes: setSelectedStoppageCodes
            }}
            equipmentNumbersState={{equipmentNumbers, setEquipmentNumbers}}
            options={DOWNTIME_DETAILS_FILTER_OPTIONS[groupType]}
            path={generatePath(PLANT_DOWNTIME_STOPPAGES_DETAILS, {
              plantId,
              groupType,
              groupId
            })}
          />
        }
        afterFilters={
          isDowntimeProductionTimeEnabled ? (
            <Box display="flex" gap={1}>
              <KpiBox
                value={equipmentProductionData?.totalNumberOfDisturbances}
                kpiName={t(`${performancePrefix}.downtime.label.totalDisturbances`)}
              />
              <KpiBox
                value={equipmentProductionData?.totalDisturbancesTime}
                unit="h"
                kpiName={t(`${performancePrefix}.downtime.label.totalDisturbancesDuration`)}
              />
            </Box>
          ) : null
        }
      />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <TopIssues
            timeRange={timeRange}
            plantId={plantId}
            stoppageConfig={stoppageConfig}
            title={t(`${performancePrefix}.downtime.label.downtimes`)}
            hideInfoIcon
            stoppageCodeStats={stoppageCodeStatsQuery.data || {}}
            showFramesLabelFromTimeRange
            topIssuesSortingKey={topIssuesSortingKey}
            setTopIssuesSortingKey={setTopIssuesSortingKey}
            topIssuesGroupingKey={topIssuesGroupingKey}
            disableGroupingKey
            sortedStoppages={sortedStoppages}
            aggregatedStoppagesQuery={aggregatedStoppagesQuery}
            onBarClick={(stoppageCode?: string) => {
              if (!stoppageCode) return

              trackAnalyticsEvent(DowntimeAnalyticsEventName.UserClicksOn3LevelStoppagesGraphBar, {
                groupId,
                group: topIssuesGroupingKey
              })
              navigate(
                generatePath(PLANT_DOWNTIME_STOPPAGES_DETAILS_FOR_CODE, {
                  plantId,
                  groupType,
                  groupId,
                  stoppageCode
                })
              )
            }}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <StoppageStatsTable
            stoppageCodeStatsQuery={stoppageCodeStatsQuery}
            stoppageConfig={stoppageConfig}
            topIssuesGroupingKey={topIssuesGroupingKey}
            sortedStoppages={sortedStoppages}
            timezone={timezone}
          />
        </Grid>
      </Grid>
    </>
  )
}
