import {CalendarTodayOutlined} from '@mui/icons-material'
import {Box, Typography, Chip} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'

export const PlantStatusCardHeader: React.FC = () => {
  const {hrocPrefix} = useTranslationPrefix()
  const {t} = useTranslation()

  return (
    <Box display="flex">
      <Box flex={1}>
        <Typography variant="h3" mb={2}>
          {t(`${hrocPrefix}.plantStatus.cardTitle`)}
        </Typography>
      </Box>
      <Chip
        icon={<CalendarTodayOutlined />}
        label={t(`${hrocPrefix}.label.yesterday`)}
        size="small"
        sx={{mx: 1}}
      />
    </Box>
  )
}
