import {TimeZone} from '@hconnect/uikit'
import {DateRangePicker} from '@hconnect/uikit/src/lib2'
import {defaultQuickSelectionItems} from '@hconnect/uikit/src/lib2/components/simpleDatePicker/dateRangePickerHelpers'
import {useTranslation} from 'react-i18next'

type Props = {
  timeRangeParam?: string
  onTimeRangeParamChange?: (timeRange: string) => void
  timezone: TimeZone
}

export const DueDateFilter = ({timeRangeParam, onTimeRangeParamChange, timezone}: Props) => {
  const {t} = useTranslation()

  return (
    <DateRangePicker
      timeRangeParam={timeRangeParam}
      onTimeRangeParamChange={onTimeRangeParamChange}
      singleDateSwitchLabel={t('quickSelection.pickSingleDate')}
      selectionItems={defaultQuickSelectionItems(t, timezone)}
      timezone={timezone}
    />
  )
}
