import {TimeRange} from './dateTime.types'
import {Location} from './location.types'

type QuarterlyForecastData = {
  quarterlyForecast: {
    quarterlyForecastValue?: number
    delta?: number
  }
}

// TODO: improvement - make the type generic so it uses the same type as the parent for descendants
export type AggregatedKpiData = Omit<KpiData, 'descendants' | 'subItems'> &
  QuarterlyForecastData & {
    descendants: AggregatedKpiData[]
    subItems: AggregatedKpiData[]
    id: string
    unit: string
  }

export type KpiData = (
  | ProductionPerformanceValues
  | KilnCoefficients
  | KilnHeatConsumptions
  | AlternativeFuelRates
  | MeanTimeBetweenFailures
  | TechnicalCementPowerConsumption
) &
  PerformanceData

export type KpiQuarterlyData =
  | QuarterlyForecastClinkerProductionHierarchy
  | QuarterlyForecastCementProductionHierarchy
  | QuarterlyForecastKilnCoefficientHierarchy
  | QuarterlyForecastKilnHeatConsumptionsHierarchy
  | QuarterlyForecastTechnicalCementPowerConsumptionsHierarchy
  | QuarterlyForecastAlternativeFuelRatesHierarchy

/** ***** HISTORICAL DATA TYPES ********* */

export type HistoricalData = {
  yearOffset: number
}

export type ProductionHistoricalData = HistoricalData & {
  actualCementProduction: number
  actualClinkerProduction: number
}

export type HistoricalKilnCoefficientData = HistoricalData & {
  actualOperatingCoefficient: number
  actualReliabilityCoefficient: number
}

export type HistoricalTechnicalCementPowerConsumptions = HistoricalData & {
  plannedTechnicalCementPowerConsumptions: number
  actualTechnicalCementPowerConsumptions: number
}

export type HistoricalKilnHeatConsumptions = HistoricalData & {
  plannedKilnHeatConsumptions: number
  actualKilnHeatConsumptions: number
}

export type HistoricalMeanTimeBetweenFailures = HistoricalData & {
  actualMeanTimeBetweenFailures: number
  plannedMeanTimeBetweenFailures: number
}

export type HistoricalAlternativeFuelRates = HistoricalData & {
  actualAlternativeFuelRates: number
  plannedAlternativeFuelRates: number
}

/** ***** RESPONSE TYPES ********* */

export type ProductionPerformanceValuesResponse = {
  plannedCementProduction: number
  actualCementProduction: number
  plannedClinkerProduction: number
  actualClinkerProduction: number
  clinkerDelta: number
  cementDelta: number
  historicalData: ProductionHistoricalData[]
  descendants: ProductionPerformanceValuesDescendantResponse[]
}

export type PlannedYearlyProductionVolumeResponse = {
  plannedCementProduction: number
  plannedClinkerProduction: number
  year: number
  unit: string
  plantId: string
}

export type ProductionPerformanceValuesDescendantResponse = Omit<
  ProductionPerformanceValuesResponse,
  'locationData'
> & {
  locationData: Location
  plannedYearlyProductionVolume: PlannedYearlyProductionVolumeResponse
}

/** ***** MAPPED TYPES ********* */

export type PerformanceData = {
  unit: string
  historicalData: HistoricalData[]
  // This is generated in the mappers if not present in response
  locationData?: Location
}

export type PerformanceDescendantWithId<T> = T & {
  id: string
  locationData?: Location
  location?: Location
  subItems: PerformanceDescendantWithId<T>[]
  unit: string
}

export type ProductionPerformanceValues = Omit<
  ProductionPerformanceValuesResponse,
  'descendants' | 'clinkerDelta' | 'cementDelta'
> & {
  descendants: PerformanceDescendantWithId<ProductionPerformanceValuesDescendant>[]
  clinkerDelta?: number
  cementDelta?: number
}

export type ProductionPerformanceValuesDescendant = ProductionPerformanceValues & {
  locationData: Location
  plannedYearlyCementProductionVolume: number
  plannedYearlyClinkerProductionVolume: number
}

type PlannedYearlyCoefficients = {
  plannedOperatingCoefficient: number
  plannedReliabilityCoefficient: number
}

export type QuarterlyForecastClinkerProductionHierarchy = {
  locationId: string
  quarterlyForecastClinkerProduction?: number
  delta?: number
  descendants: QuarterlyForecastClinkerProductionHierarchy[]
}

export type QuarterlyForecastCementProductionHierarchy = {
  locationId: string
  quarterlyForecastCementProduction?: number
  delta?: number
  descendants: QuarterlyForecastCementProductionHierarchy[]
}

export type QuarterlyForecastKilnCoefficientHierarchy = {
  locationId: string
  quarterlyForecastOperatingCoefficient?: number
  delta?: number
  descendants: QuarterlyForecastKilnCoefficientHierarchy[]
}

export type KilnCoefficientsResponse = {
  actualOperatingCoefficient: number
  actualReliabilityCoefficient: number
  plannedOperatingCoefficient: number
  plannedReliabilityCoefficient: number
  descendants: KilnCoefficientsResponse[]
  operatingDelta?: number
  reliabilityDelta?: number
  historicalData: HistoricalKilnCoefficientData[]
  location: Location
  plantId?: string
}

export type KilnCoefficients = PlannedYearlyCoefficients & {
  actualOperatingCoefficient: number
  actualReliabilityCoefficient: number
  plannedOperatingCoefficient: number
  plannedReliabilityCoefficient: number
  descendants: PerformanceDescendantWithId<KilnCoefficients>[]
  operatingDelta?: number
  reliabilityDelta?: number
  historicalData: HistoricalKilnCoefficientData[]
  locationData: Location
  plantId?: string
  unit: string
}

export type TechnicalCementPowerConsumption = {
  actualTechnicalCementPowerConsumptions: number
  plannedTechnicalCementPowerConsumptions: number
  descendants: PerformanceDescendantWithId<TechnicalCementPowerConsumption>[]
  delta?: number
  historicalData: HistoricalTechnicalCementPowerConsumptions[]
  locationData: Location
  plantId?: string
  unit: string
  plannedYearlyTechnicalCementPowerConsumptions?: number
}

export type QuarterlyForecastTechnicalCementPowerConsumptionsHierarchy = {
  locationId: string
  quarterlyForecastTechnicalCementPowerConsumptions?: number
  delta?: number
  descendants: QuarterlyForecastTechnicalCementPowerConsumptionsHierarchy[]
}

export type TechnicalCementPowerConsumptionResponse = {
  actualTechnicalCementPowerConsumptions: number
  plannedTechnicalCementPowerConsumptions: number
  descendants: TechnicalCementPowerConsumptionResponse[]
  delta?: number
  historicalData: HistoricalTechnicalCementPowerConsumptions[]
  location: Location
  plantId?: string
}

export type KilnHeatConsumptions = {
  actualKilnHeatConsumptions: number
  plannedKilnHeatConsumptions: number
  descendants: PerformanceDescendantWithId<KilnHeatConsumptions>[]
  delta?: number
  historicalData: HistoricalKilnHeatConsumptions[]
  locationData: Location
  plantId?: string
  plannedYearlyKilnHeatConsumptions: number
  unit: string
}

export type QuarterlyForecastKilnHeatConsumptionsHierarchy = {
  locationId: string
  quarterlyForecastKilnHeatConsumptions?: number
  delta?: number
  descendants: QuarterlyForecastKilnHeatConsumptionsHierarchy[]
}

export type KilnHeatConsumptionsResponse = {
  actualKilnHeatConsumptions: number
  plannedKilnHeatConsumptions: number
  descendants: KilnHeatConsumptionsResponse[]
  delta?: number
  historicalData: HistoricalKilnHeatConsumptions[]
  location: Location
  plantId?: string
}

export type MeanTimeBetweenFailures = {
  actualMeanTimeBetweenFailures: number
  plannedMeanTimeBetweenFailures: number
  plannedYearlyProductionVolume?: number
  descendants: PerformanceDescendantWithId<MeanTimeBetweenFailures>[]
  delta?: number
  historicalData: HistoricalMeanTimeBetweenFailures[]
  locationData: Location
  unit: string
  plantId?: string
}

export type MeanTimeBetweenFailuresResponse = {
  actualMeanTimeBetweenFailures: number
  plannedMeanTimeBetweenFailures: number
  descendants: MeanTimeBetweenFailuresResponse[]
  delta?: number
  historicalData: HistoricalMeanTimeBetweenFailures[]
  location: Location
  plantId?: string
}

export type AlternativeFuelRates = {
  plantId: string
  actualAlternativeFuelRates: number
  plannedAlternativeFuelRates: number
  delta?: number
  unit: string
  locationData: Location
  descendants: PerformanceDescendantWithId<AlternativeFuelRates>[]
  historicalData: HistoricalAlternativeFuelRates[]
  plannedYearlyProductionVolume?: number
}

export type QuarterlyForecastAlternativeFuelRatesHierarchy = {
  locationId: string
  quarterlyForecastAlternativeFuelRates?: number
  delta?: number
  descendants: QuarterlyForecastAlternativeFuelRatesHierarchy[]
}

export type AlternativeFuelRatesResponse = Omit<
  AlternativeFuelRates,
  'unit' | 'plannedYearlyProductionVolume' | 'locationData'
> & {
  descendants: AlternativeFuelRatesResponse[]
  location: Location
}

export type PlannedAlternativeFuelRatesHierarchy = {
  plantId: string
  descendants: PlannedAlternativeFuelRatesHierarchy[]
  location: Location
  plannedAlternativeFuelRates: number
}

export type PlannedYearlyKilnHeatConsumptionsHierarchy = {
  plantId: string
  descendants: PlannedYearlyKilnHeatConsumptionsHierarchy[]
  location: Location
  plannedKilnHeatConsumptions: number
}

export type PlannedYearlyMeanTimeBetweenFailuresHierarchy = {
  plantId: string
  descendants: PlannedYearlyMeanTimeBetweenFailuresHierarchy[]
  location: Location
  plannedMeanTimeBetweenFailures: number
}

export type PlannedYearlyTechnicalCementPowerConsumptionsHierarchy = {
  plantId: string
  descendants: PlannedYearlyTechnicalCementPowerConsumptionsHierarchy[]
  location: Location
  plannedTechnicalCementPowerConsumptions: number
}

export type PlannedYearlyKilnCoefficientHierarchy = {
  plantId: string
  descendants: PlannedYearlyKilnCoefficientHierarchy[]
  location: Location
  plannedOperatingCoefficient: number
  plannedReliabilityCoefficient: number
}

export type KpiDataGeneratorData = {
  alternativeFuelRatesData?: AlternativeFuelRates
  meanTimeBetweenFailuresData?: MeanTimeBetweenFailures
  kilnCoefficientData?: KilnCoefficients
  kilnHeatConsumptionsData?: KilnHeatConsumptions
  technicalCementPowerConsumptionData?: TechnicalCementPowerConsumption
}

export type KpiDataGeneratorError = {
  alternativeFuelRatesError: boolean
  meanTimeBetweenFailuresError: boolean
  kilnCoefficientError: boolean
  kilnHeatConsumptionsError: boolean
  technicalCementPowerConsumptionError: boolean
}

export type KpiPfyPayload = {
  locationId?: string
  year?: number
}

interface CommonKpiFetchParams {
  timezone: string
  locationId?: string
  showYTM?: boolean
}

/**
 * @description MTM and YTM are made for KPIs on location and plant performance pages
 * timestamp is used for mtm and ytm endpoints
 */
export interface MtmYtmRequestPayload extends CommonKpiFetchParams {
  timestamp: string
  year?: number
}

/**
 * @description Production performance values (clinker/cement) are fetched on location pages
 * DateRange is used BE endpoints which is generated(in DATE_ONLY_REQUEST_FORMAT) from TimeRange
 */
export interface ProductionRequestPayload extends CommonKpiFetchParams {
  yearOffset?: number // offset for the past data to be fetched
  timeRange: TimeRange
}
