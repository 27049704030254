import {FlexPage} from '@hconnect/common/components/FlexPage'
import {useWidth} from '@hconnect/common/hooks/useWidth'
import {Grid} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {TasksFilters} from '../containers'
import {useTasksPageFilters} from '../hooks'
import {EventsTableLayout} from '../layouts'

export const Tasks: React.FC = () => {
  const {t} = useTranslation()
  const {tasksPrefix} = useTranslationPrefix()
  const breakPoint = useWidth()
  const isSmall = breakPoint === 'xs' || breakPoint === 'sm'
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const {filters, updateFilters} = useTasksPageFilters()

  return (
    <FlexPage
      useViewportHeight={!isSmall}
      title={t(`${tasksPrefix}.pageTitle`)}
      headerActionContent={
        <Grid spacing={1} container alignItems="center" justifyContent="flex-end" wrap="nowrap">
          <Grid item>
            <TasksFilters
              translationPrefix={tasksPrefix}
              filters={filters}
              onFiltersChange={updateFilters}
              timezone={timezone}
            />
          </Grid>
        </Grid>
      }
    >
      <EventsTableLayout translationPrefix={tasksPrefix} timezone={timezone} />
    </FlexPage>
  )
}
