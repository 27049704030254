import {useFormContext} from 'react-hook-form'

import {StepFormActions} from '../components/StepFormActions'
import {useSteppedFormContext} from '../hooks/useStepperFormContext'

export const StepFormActionsContainer = () => {
  const {
    onCancel,
    updateCurrentStepValues,
    stepActions,
    isSubmitting,
    readOnly,
    backToTargetLabel
  } = useSteppedFormContext()
  const {
    formState: {isValid, isSubmitting: isStepSubmitting},
    getValues
  } = useFormContext()

  return (
    <StepFormActions
      onCancel={onCancel}
      onGoToPrevStep={() => {
        updateCurrentStepValues(getValues())
        stepActions.goToPrevStep()
      }}
      stepActions={stepActions}
      isValid={isValid}
      isSubmitting={isSubmitting || isStepSubmitting}
      readOnly={readOnly}
      backToTargetLabel={backToTargetLabel}
    />
  )
}
