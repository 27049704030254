import {TimeZone} from '@hconnect/uikit'

import {FilterTextField} from '../../../shared/components'
import {
  DueDateFilter,
  PlantFilter,
  PlantRequiredTooltip,
  PomActionSourceFilter,
  PomQuestionCategoryFilter,
  PomQuestionFilter,
  PomSectionFilter,
  PomTopicFilter,
  TaskCategoryFilter,
  TaskEquipmentSearchFilter,
  TaskMainEquipmentSearchFilter,
  TaskPriorityFilter,
  TaskProcessStageFilter,
  TaskStatusFilter
} from '../../components/filters'
import {FilterIncompatibleTooltip} from '../../components/filters/FilterIncompatibleTooltip'
import {TasksFilterKey, TasksFilterKeyName} from '../../consts'
import {FilterConfig, TasksFiltersState, TaskSource} from '../../types'
import {isFilterCompatibleWithVisibleFilters} from '../../utils'

const filterSourcesConfig: Record<TasksFilterKeyName, TaskSource[]> = {
  [TasksFilterKey.TITLE]: [TaskSource.COCKPIT, TaskSource.EASY_POM],
  [TasksFilterKey.DUE_DATE_RANGE]: [TaskSource.COCKPIT, TaskSource.EASY_POM],
  [TasksFilterKey.STATUSES]: [TaskSource.COCKPIT, TaskSource.EASY_POM],
  [TasksFilterKey.CATEGORIES]: [TaskSource.COCKPIT],
  [TasksFilterKey.PRIORITIES]: [TaskSource.COCKPIT],
  [TasksFilterKey.PLANT]: [TaskSource.COCKPIT, TaskSource.EASY_POM],
  [TasksFilterKey.PROCESS_STAGES]: [TaskSource.COCKPIT],
  [TasksFilterKey.EQUIPMENT]: [TaskSource.COCKPIT],
  [TasksFilterKey.MAIN_EQUIPMENT]: [TaskSource.COCKPIT],
  [TasksFilterKey.POM_ACTION_SOURCES]: [TaskSource.EASY_POM],
  [TasksFilterKey.POM_QUESTION_CATEGORIES]: [TaskSource.EASY_POM],
  [TasksFilterKey.POM_SECTIONS]: [TaskSource.EASY_POM],
  [TasksFilterKey.POM_TOPICS]: [TaskSource.EASY_POM],
  [TasksFilterKey.POM_QUESTIONS]: [TaskSource.EASY_POM]
}

const plantRequiredFilters: TasksFilterKeyName[] = [
  TasksFilterKey.PROCESS_STAGES,
  TasksFilterKey.EQUIPMENT,
  TasksFilterKey.MAIN_EQUIPMENT
]

const isPlantRequired = (filterKey: TasksFilterKeyName): boolean => {
  return plantRequiredFilters.includes(filterKey)
}

const getFilterAvailabilityProps = ({
  key,
  visibleFilters,
  translationPrefix,
  label,
  hasPlantSelected
}: Pick<CreateConfigParams, 'key' | 'visibleFilters' | 'translationPrefix'> & {
  label: string
  hasPlantSelected?: boolean
}) => {
  const props: {disabled?: boolean; tooltipTitle?: React.ReactNode} = {}

  if (!isFilterCompatibleWithVisibleFilters(key, visibleFilters, filterSourcesConfig)) {
    props.disabled = true
    props.tooltipTitle = <FilterIncompatibleTooltip translationPrefix={translationPrefix} />
  } else if (isPlantRequired(key) && !hasPlantSelected) {
    props.disabled = true
    props.tooltipTitle = (
      <PlantRequiredTooltip translationPrefix={translationPrefix} disabledFilter={label} />
    )
  }

  return props
}

export type TasksFiltersStateValue = TasksFiltersState[keyof TasksFiltersState]

export type TaskFilterConfig = FilterConfig<TasksFilterKeyName>

type CreateConfigParams = {
  key: TasksFilterKeyName
  label: string
  filters: TasksFiltersState
  onChange: (key: TasksFilterKeyName, value: TasksFiltersStateValue) => void
  clearFilter: (key: TasksFilterKeyName) => void
  translationPrefix: string
  visibleFilters: TasksFilterKeyName[]
}

export const createTextFilter = ({
  key,
  label,
  filters,
  onChange,
  translationPrefix,
  visibleFilters
}: CreateConfigParams): TaskFilterConfig => ({
  value: key,
  label,
  filter: (
    <FilterTextField<TasksFilterKeyName>
      name={key}
      label={label}
      value={filters[key] as string}
      onChange={onChange}
    />
  ),
  ...getFilterAvailabilityProps({key, visibleFilters, translationPrefix, label})
})

export const createDateRangeFilter = ({
  key,
  label,
  filters,
  onChange,
  timezone,
  translationPrefix,
  visibleFilters
}: CreateConfigParams & {timezone: TimeZone}): TaskFilterConfig => ({
  value: key,
  label,
  filter: (
    <DueDateFilter
      timeRangeParam={filters[key] as string}
      onTimeRangeParamChange={(value) => onChange(key, value)}
      timezone={timezone}
    />
  ),
  ...getFilterAvailabilityProps({key, visibleFilters, translationPrefix, label})
})

export const createCategoriesFilter = ({
  key,
  label,
  filters,
  onChange,
  clearFilter,
  translationPrefix,
  visibleFilters
}: CreateConfigParams): TaskFilterConfig => ({
  value: key,
  label,
  filter: (
    <TaskCategoryFilter<TasksFilterKeyName>
      name={key}
      label={label}
      value={filters.categories}
      onChange={onChange}
      clearFilter={clearFilter}
      translationPrefix={translationPrefix}
    />
  ),
  ...getFilterAvailabilityProps({key, visibleFilters, translationPrefix, label})
})

export const createStatusesFilter = ({
  key,
  label,
  filters,
  onChange,
  clearFilter,
  translationPrefix,
  visibleFilters
}: CreateConfigParams): TaskFilterConfig => ({
  value: key,
  label,
  filter: (
    <TaskStatusFilter<TasksFilterKeyName>
      name={key}
      label={label}
      value={filters.statuses}
      onChange={onChange}
      clearFilter={clearFilter}
      translationPrefix={translationPrefix}
    />
  ),
  ...getFilterAvailabilityProps({key, visibleFilters, translationPrefix, label})
})

export const createPrioritiesFilter = ({
  key,
  label,
  filters,
  onChange,
  clearFilter,
  translationPrefix,
  visibleFilters
}: CreateConfigParams): TaskFilterConfig => ({
  value: key,
  label,
  filter: (
    <TaskPriorityFilter<TasksFilterKeyName>
      name={key}
      label={label}
      value={filters.priorities}
      onChange={onChange}
      clearFilter={clearFilter}
      translationPrefix={translationPrefix}
    />
  ),
  ...getFilterAvailabilityProps({key, visibleFilters, translationPrefix, label})
})

export const createPomActionSourceFilter = ({
  key,
  label,
  filters,
  onChange,
  clearFilter,
  translationPrefix,
  visibleFilters
}: CreateConfigParams): TaskFilterConfig => ({
  value: key,
  label,
  filter: (
    <PomActionSourceFilter<TasksFilterKeyName>
      name={key}
      label={label}
      value={filters.pomActionSources}
      onChange={onChange}
      clearFilter={clearFilter}
      translationPrefix={translationPrefix}
    />
  ),
  ...getFilterAvailabilityProps({key, visibleFilters, translationPrefix, label})
})

export const createPomSectionFilter = ({
  key,
  label,
  filters,
  onChange,
  clearFilter,
  translationPrefix,
  visibleFilters
}: CreateConfigParams): TaskFilterConfig => ({
  value: key,
  label,
  filter: (
    <PomSectionFilter<TasksFilterKeyName>
      name={key}
      label={label}
      value={filters.pomSections}
      onChange={onChange}
      clearFilter={clearFilter}
      translationPrefix={translationPrefix}
    />
  ),
  ...getFilterAvailabilityProps({key, visibleFilters, translationPrefix, label})
})

export const createPomTopicFilter = ({
  key,
  label,
  filters,
  onChange,
  clearFilter,
  translationPrefix,
  visibleFilters
}: CreateConfigParams): TaskFilterConfig => ({
  value: key,
  label,
  filter: (
    <PomTopicFilter<TasksFilterKeyName>
      name={key}
      label={label}
      value={filters.pomTopics}
      onChange={onChange}
      clearFilter={clearFilter}
      translationPrefix={translationPrefix}
      selectedSectionIds={filters.pomSections}
    />
  ),
  ...getFilterAvailabilityProps({key, visibleFilters, translationPrefix, label})
})

export const createPomQuestionFilter = ({
  key,
  label,
  filters,
  onChange,
  clearFilter,
  translationPrefix,
  visibleFilters
}: CreateConfigParams): TaskFilterConfig => ({
  value: key,
  label,
  filter: (
    <PomQuestionFilter<TasksFilterKeyName>
      name={key}
      label={label}
      value={filters.pomQuestions}
      onChange={onChange}
      clearFilter={clearFilter}
      translationPrefix={translationPrefix}
      selectedSectionIds={filters.pomSections}
      selectedTopicIds={filters.pomTopics}
    />
  ),
  ...getFilterAvailabilityProps({key, visibleFilters, translationPrefix, label})
})

export const createPomQuestionCategoryFilter = ({
  key,
  label,
  filters,
  onChange,
  clearFilter,
  translationPrefix,
  visibleFilters
}: CreateConfigParams): TaskFilterConfig => ({
  value: key,
  label,
  filter: (
    <PomQuestionCategoryFilter<TasksFilterKeyName>
      name={key}
      label={label}
      value={filters.pomQuestionCategories}
      onChange={onChange}
      clearFilter={clearFilter}
      translationPrefix={translationPrefix}
    />
  ),
  ...getFilterAvailabilityProps({key, visibleFilters, translationPrefix, label})
})

export const createPlantFilter = ({
  key,
  label,
  filters,
  onChange,
  clearFilter,
  translationPrefix,
  visibleFilters
}: CreateConfigParams): TaskFilterConfig => ({
  value: key,
  label,
  filter: (
    <PlantFilter<TasksFilterKeyName>
      name={key}
      label={label}
      value={filters.plant}
      onChange={onChange}
      clearFilter={clearFilter}
      translationPrefix={translationPrefix}
    />
  ),
  ...getFilterAvailabilityProps({key, visibleFilters, translationPrefix, label})
})

export const createProcessStagesFilter = ({
  key,
  label,
  filters,
  onChange,
  clearFilter,
  translationPrefix,
  visibleFilters
}: CreateConfigParams): TaskFilterConfig => {
  const plantFilterValue = filters.plant

  return {
    value: key,
    label,
    filter: !!plantFilterValue && (
      <TaskProcessStageFilter<TasksFilterKeyName>
        name={key}
        label={label}
        value={filters.processStages}
        onChange={onChange}
        clearFilter={clearFilter}
        translationPrefix={translationPrefix}
        plantId={plantFilterValue}
      />
    ),
    ...getFilterAvailabilityProps({
      key,
      visibleFilters,
      translationPrefix,
      label,
      hasPlantSelected: !!plantFilterValue
    })
  }
}

export const createMainEquipmentFilter = ({
  key,
  label,
  filters,
  onChange,
  clearFilter,
  translationPrefix,
  visibleFilters
}: CreateConfigParams): TaskFilterConfig => {
  const plantFilterValue = filters.plant

  return {
    value: key,
    label,
    filter: !!plantFilterValue && (
      <TaskMainEquipmentSearchFilter<TasksFilterKeyName>
        name={key}
        label={label}
        value={filters.mainEquipment}
        onChange={onChange}
        clearFilter={clearFilter}
        plantId={plantFilterValue}
        translationPrefix={translationPrefix}
      />
    ),
    ...getFilterAvailabilityProps({
      key,
      visibleFilters,
      translationPrefix,
      label,
      hasPlantSelected: !!plantFilterValue
    })
  }
}

export const createEquipmentFilter = ({
  key,
  label,
  filters,
  onChange,
  clearFilter,
  translationPrefix,
  visibleFilters
}: CreateConfigParams): TaskFilterConfig => {
  const plantFilterValue = filters.plant

  return {
    value: key,
    label,
    filter: !!plantFilterValue && (
      <TaskEquipmentSearchFilter<TasksFilterKeyName>
        name={key}
        label={label}
        value={filters.equipment}
        onChange={onChange}
        clearFilter={clearFilter}
        plantId={plantFilterValue}
        translationPrefix={translationPrefix}
      />
    ),
    ...getFilterAvailabilityProps({
      key,
      visibleFilters,
      translationPrefix,
      label,
      hasPlantSelected: !!plantFilterValue
    })
  }
}
