import {SectionDto, TopicDto} from '@hconnect/common/types'
import {getFilteredTopics} from '@hconnect/common/utils'

import {FilterMultiSelect, FilterMultiSelectProps} from '../../../shared/components'
import {usePomSections} from '../../hooks'

const mapSection = ({sectionId, topics}: SectionDto) => ({id: sectionId, topics})

const getTopicsOptions = (sections: SectionDto[], selectedSectionIds?: string[]) =>
  getFilteredTopics<TopicDto, {id: string; topics: TopicDto[]}>(
    sections.map(mapSection),
    selectedSectionIds
  ).map(({topicId}) => ({
    value: topicId,
    label: topicId
  }))

export const PomTopicFilter = <TName extends string>(
  props: Omit<FilterMultiSelectProps<string, TName>, 'options'> & {selectedSectionIds?: string[]}
) => {
  const {data: options = []} = usePomSections({
    select: (sections) => getTopicsOptions(sections, props.selectedSectionIds)
  })

  return <FilterMultiSelect {...props} options={options} />
}
