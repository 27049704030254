import {SectionDto} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {ApiPath} from '../../../shared/consts'
import {useAxios} from '../../../shared/hooks/useApi'

const PomQuestionnairesSectionsQueryKey = 'pom-questionnaires-sections'

export const usePomSections = <TSelect = SectionDto[]>(
  options?: UseQueryOptions<SectionDto[], AxiosError, TSelect>
) => {
  const axiosInstance = useAxios()
  const path = `${ApiPath.POM_QUESTIONNARIES}/sections`

  return useQuery<SectionDto[], AxiosError, TSelect>({
    queryKey: [PomQuestionnairesSectionsQueryKey],
    queryFn: async () => {
      const response = await axiosInstance.get<SectionDto[]>(path)

      return response.data ?? []
    },
    ...options
  })
}
