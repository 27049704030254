import {KpiTrendChartData} from './kpi.types'

type KpiResultDto = {
  value?: number
  unit?: string
}

export type MonthlyKpiSummaryDto = {
  year: number
  month: number
  clinkerProduction: KpiResultDto
  cementProduction: KpiResultDto
  alternativeFuelRate: KpiResultDto
  kilnHeatConsumption: KpiResultDto
  rawMillsPowerConsumption: KpiResultDto
  kilnPowerConsumption: KpiResultDto
  cementMillsPowerConsumption: KpiResultDto
  rawMillsProductionRate: KpiResultDto
  clinkerProductionRate: KpiResultDto
  cementMillsProductionRate: KpiResultDto
}

export type ProcessEngineerMontlyKpiTrends = {
  clinkerProduction: KpiTrendChartData
  cementProduction: KpiTrendChartData
  kilnHeatConsumption: KpiTrendChartData
  rawMillsPowerConsumption: KpiTrendChartData
  kilnPowerConsumption: KpiTrendChartData
  cementMillsPowerConsumption: KpiTrendChartData
  rawMillsProductionRate: KpiTrendChartData
  clinkerProductionRate: KpiTrendChartData
  cementMillsProductionRate: KpiTrendChartData
}
