import {OperationHoursLegendTooltip} from '@hconnect/common/components/runningTimes'
import {EquipmentRunningTimesDto as EquipmentRunningTimes} from '@hconnect/common/types'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {ExpandMore, ExpandLess} from '@mui/icons-material'
import {Box, Grid, Typography, IconButton, Collapse} from '@mui/material'
import {isEmpty} from 'lodash'
import React from 'react'
import {generatePath, useNavigate} from 'react-router-dom'

import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {PLANT_KPI_STATUS, STOPPAGE_ANALYSIS_STATUS} from '../routing'
import {HierarchyNode} from '../types'

import {OperationHoursTable} from './OperationHours'

export type PlantSummaryCardProps = {
  equipmentRunningTimes: EquipmentRunningTimes[]
  plantNode: HierarchyNode
  startDate: string
  endDate: string
  'data-test-id'?: string
}

export const PlantSummaryCard: React.FC<PlantSummaryCardProps> = ({
  equipmentRunningTimes,
  startDate,
  endDate,
  plantNode,
  'data-test-id': dataTestId
}) => {
  const {performancePrefix} = useTranslationPrefix()
  const navigate = useNavigate()
  const [expanded, setExpanded] = React.useState(true)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const navigateToPlant = () => {
    navigate(generatePath(PLANT_KPI_STATUS, {plantId: plantNode.id}))
  }

  const handleNavigateToStoppageAnalysis = (equipmentId?: string) => {
    if (equipmentId) {
      navigate(
        generatePath(STOPPAGE_ANALYSIS_STATUS, {
          plantId: plantNode.id,
          equipmentId
        })
      )
    }
  }

  return (
    <Grid data-test-id={dataTestId}>
      <CardBox>
        <Grid container wrap="nowrap" justifyContent="space-between">
          <Grid item flex="auto">
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={handleExpandClick}
                sx={{marginRight: 1}}
                data-test-id="plant-summary-card-collapse-button"
              >
                {expanded ? <ExpandMore /> : <ExpandLess />}
              </IconButton>

              <Typography variant="h3" sx={{cursor: 'pointer'}} onClick={navigateToPlant}>
                {plantNode.name}
              </Typography>
              <OperationHoursLegendTooltip translationPrefix={performancePrefix} />
            </Box>
          </Grid>
        </Grid>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {!isEmpty(equipmentRunningTimes) && (
            <OperationHoursTable
              noCard
              noChartsSeparator
              startDate={startDate}
              endDate={endDate}
              equipmentRunningTimes={equipmentRunningTimes}
              onClick={handleNavigateToStoppageAnalysis}
              hideLegend={true}
              plantId={plantNode.id}
            />
          )}
        </Collapse>
      </CardBox>
    </Grid>
  )
}
