import {ActionSource, ACTION_SOURCES, QuestionDifficulty} from '../types'

export const isActionSource = (value: string | undefined): value is ActionSource =>
  ACTION_SOURCES.includes(value as ActionSource)

export const isQuestionDifficulty = (value: string | undefined): value is QuestionDifficulty =>
  Object.values(QuestionDifficulty).includes(value as QuestionDifficulty)

const filterSectionsByIds = <TSection extends {id: string}>(
  sections: TSection[],
  selectedSectionIds?: string[]
): TSection[] =>
  sections.filter(({id}) => !selectedSectionIds?.length || selectedSectionIds.includes(id))

export const getFilteredTopics = <TTopic, TSection extends {id: string; topics: TTopic[]}>(
  sections: TSection[],
  selectedSectionIds?: string[]
): TTopic[] => filterSectionsByIds(sections, selectedSectionIds).flatMap(({topics}) => topics)

export const getFilteredQuestions = <
  TQuestion,
  TTopic extends {id: string; questions?: TQuestion[]},
  TSection extends {id: string; topics: TTopic[]}
>(
  sections: TSection[],
  sectionIds?: string[],
  topicIds?: string[]
): TQuestion[] =>
  getFilteredTopics<TTopic, TSection>(sections, sectionIds)
    .filter((topic) => !topicIds?.length || topicIds.includes(topic.id))
    .flatMap(({questions}) => questions ?? [])
