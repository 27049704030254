import moment from 'moment'

import type {
  KpiTrendChartData,
  MonthlyKpiSummaryDto,
  ProcessEngineerMontlyKpiTrends
} from '../types'

export const mapToProcessEngineerMonthlyKpiTrends = (
  data?: MonthlyKpiSummaryDto[]
): ProcessEngineerMontlyKpiTrends => {
  const clinkerProduction: KpiTrendChartData = {labels: [], data: []}
  const cementProduction: KpiTrendChartData = {labels: [], data: []}
  const kilnHeatConsumption: KpiTrendChartData = {labels: [], data: []}
  const rawMillsPowerConsumption: KpiTrendChartData = {labels: [], data: []}
  const kilnPowerConsumption: KpiTrendChartData = {labels: [], data: []}
  const cementMillsPowerConsumption: KpiTrendChartData = {labels: [], data: []}
  const rawMillsProductionRate: KpiTrendChartData = {labels: [], data: []}
  const clinkerProductionRate: KpiTrendChartData = {labels: [], data: []}
  const cementMillsProductionRate: KpiTrendChartData = {labels: [], data: []}

  data?.forEach((item) => {
    const label = moment.utc([item.year, item.month - 1]).toISOString()
    clinkerProduction.labels.push(label)
    cementProduction.labels.push(label)
    kilnHeatConsumption.labels.push(label)
    rawMillsPowerConsumption.labels.push(label)
    kilnPowerConsumption.labels.push(label)
    cementMillsPowerConsumption.labels.push(label)
    rawMillsProductionRate.labels.push(label)
    clinkerProductionRate.labels.push(label)
    cementMillsProductionRate.labels.push(label)

    clinkerProduction.data.push(item.clinkerProduction?.value ?? 0)
    cementProduction.data.push(item.cementProduction?.value ?? 0)
    kilnHeatConsumption.data.push(item.kilnHeatConsumption?.value ?? 0)
    rawMillsPowerConsumption.data.push(item.rawMillsPowerConsumption?.value ?? 0)
    kilnPowerConsumption.data.push(item.kilnPowerConsumption?.value ?? 0)
    cementMillsPowerConsumption.data.push(item.cementMillsPowerConsumption?.value ?? 0)
    rawMillsProductionRate.data.push(item.rawMillsProductionRate?.value ?? 0)
    clinkerProductionRate.data.push(item.clinkerProductionRate?.value ?? 0)
    cementMillsProductionRate.data.push(item.cementMillsProductionRate?.value ?? 0)
  })

  return {
    clinkerProduction,
    cementProduction,
    kilnHeatConsumption,
    rawMillsPowerConsumption,
    kilnPowerConsumption,
    cementMillsPowerConsumption,
    rawMillsProductionRate,
    clinkerProductionRate,
    cementMillsProductionRate
  }
}
