import {QuestionDifficulty} from '@hconnect/common/types'
import {getTranslationKey} from '@hconnect/common/utils'
import {useTranslation} from 'react-i18next'

import {FilterMultiSelect, FilterMultiSelectProps} from '../../../shared/components'
import {FILTER_VALUE_SEPARATOR} from '../../../shared/consts'

export const PomQuestionCategoryFilter = <TName extends string>({
  translationPrefix,
  ...props
}: Omit<FilterMultiSelectProps<QuestionDifficulty, TName>, 'options'>) => {
  const {t} = useTranslation()
  const options = Object.values(QuestionDifficulty).map((difficulty) => ({
    value: difficulty,
    label: t(getTranslationKey(`pomAction.questionDifficulty.${difficulty}`, translationPrefix))
  }))

  return (
    <FilterMultiSelect
      {...props}
      translationPrefix={translationPrefix}
      options={options}
      renderValue={(value) =>
        value
          .map((difficulty) =>
            t(getTranslationKey(`pomAction.questionDifficulty.${difficulty}`, translationPrefix))
          )
          .join(FILTER_VALUE_SEPARATOR)
      }
    />
  )
}
