import {PlantStatus, PlantStatusResponse} from '../types'

export const mapPlantStatusFromDto = (data: PlantStatusResponse): PlantStatus => ({
  plant: data.plant,
  plannedCementProduction:
    data.actualProductionValue?.plannedCementProduction === undefined ||
    data.actualProductionValue?.plannedCementProduction === null
      ? data.totalCement
      : data.actualProductionValue.plannedCementProduction,
  plannedClinkerProduction:
    data.actualProductionValue?.plannedClinkerProduction === undefined ||
    data.actualProductionValue?.plannedClinkerProduction === null
      ? data.totalClinker
      : data.actualProductionValue.plannedClinkerProduction,
  actualCementProduction: data.actualProductionValue?.actualCementProduction,
  actualClinkerProduction: data.actualProductionValue?.actualClinkerProduction,
  totalCement: data.totalCement,
  totalClinker: data.totalClinker,
  unitCement: data.unitCement,
  unitClinker: data.unitClinker,
  equipmentRunningTimes: data.equipmentRunningTimes,
  equipments: data.equipments || [],
  plannedYearlyProductionVolume: data.plannedYearlyProductionVolume,
  clinkerDelta:
    data.actualProductionValue?.actualClinkerProduction === 0 ||
    data.actualProductionValue?.plannedClinkerProduction === 0
      ? undefined
      : data.actualProductionValue?.clinkerDelta,
  cementDelta:
    data.actualProductionValue?.actualCementProduction === 0 ||
    data.actualProductionValue?.plannedCementProduction === 0
      ? undefined
      : data.actualProductionValue?.cementDelta
})
