import plants from '@hconnect/common/hproduce/plants.json'
import {orderBy, round, omit} from 'lodash'

import {TabIndexDataMap} from '../helpers'
import {
  KilnCoefficients,
  KpisList,
  ProductionPerformanceValues,
  ProductionPerformanceValuesDescendantResponse,
  ProductionPerformanceValuesResponse,
  MeanTimeBetweenFailures,
  HistoricalMeanTimeBetweenFailures,
  TechnicalCementPowerConsumption,
  AlternativeFuelRates,
  HistoricalAlternativeFuelRates,
  AlternativeFuelRatesResponse,
  KilnHeatConsumptions,
  ProductionHistoricalData,
  PerformanceDescendantWithId,
  ProductionPerformanceValuesDescendant,
  PerformanceData,
  Location,
  KilnCoefficientsResponse,
  KilnHeatConsumptionsResponse,
  MeanTimeBetweenFailuresResponse,
  TechnicalCementPowerConsumptionResponse,
  PlannedAlternativeFuelRatesHierarchy,
  PlannedYearlyTechnicalCementPowerConsumptionsHierarchy,
  PlannedYearlyMeanTimeBetweenFailuresHierarchy,
  PlannedYearlyKilnHeatConsumptionsHierarchy,
  PlannedYearlyKilnCoefficientHierarchy,
  KpiData,
  KpiQuarterlyData,
  AggregatedKpiData,
  QuarterlyForecastCementProductionHierarchy,
  QuarterlyForecastClinkerProductionHierarchy
} from '../types'

const UNITS = {
  [KpisList.ClinkerProduction]: 'kt',
  [KpisList.CementProduction]: 'kt',
  [KpisList.KilnReliabilityCoefficient]: '%',
  [KpisList.KilnOperatingCoefficient]: '%',
  [KpisList.KilnHeatConsumption]: 'GJ/t CLI',
  [KpisList.AlternativeFuelRate]: '%',
  [KpisList.MTBF]: 'h',
  [KpisList.TechnicalCementPowerCons]: 'kWh/t CEM'
}
const KILO = 1000
const convertFromTonsToKilotons = (value: number) => round(value / KILO)

const getRoundedToDecimal = (num: number, decimalPoint: number) => {
  const regex = new RegExp('^-?\\d+(?:.\\d{0,' + decimalPoint + '})?')
  const value = num.toString().match(regex)
  return value ? parseFloat(value[0]) : 0
}

const extractDelta = (planned: number, actual: number, delta?: number): number | undefined =>
  actual === 0 || planned === 0 ? undefined : delta

const mapProductionHistoricalDataDto = (
  data: ProductionHistoricalData
): ProductionHistoricalData => ({
  ...data,
  actualCementProduction: convertFromTonsToKilotons(data.actualCementProduction),
  actualClinkerProduction: convertFromTonsToKilotons(data.actualClinkerProduction)
})

const mapProductionPerformanceValuesDescendantsDto = (
  data: ProductionPerformanceValuesDescendantResponse[]
): PerformanceDescendantWithId<ProductionPerformanceValuesDescendant>[] =>
  data.map((descendant) => ({
    ...descendant,
    id: descendant.locationData.id,
    unit: UNITS[KpisList.ClinkerProduction],
    historicalData: descendant.historicalData.map(mapProductionHistoricalDataDto),
    subItems: mapProductionPerformanceValuesDescendantsDto(descendant.descendants),
    descendants: mapProductionPerformanceValuesDescendantsDto(descendant.descendants),
    plannedCementProduction: convertFromTonsToKilotons(descendant.plannedCementProduction),
    plannedClinkerProduction: convertFromTonsToKilotons(descendant.plannedClinkerProduction),
    actualCementProduction: convertFromTonsToKilotons(descendant.actualCementProduction),
    actualClinkerProduction: convertFromTonsToKilotons(descendant.actualClinkerProduction),
    cementDelta: extractDelta(
      descendant.plannedCementProduction,
      descendant.actualCementProduction,
      descendant.cementDelta
    ),
    clinkerDelta: extractDelta(
      descendant.plannedClinkerProduction,
      descendant.actualClinkerProduction,
      descendant.clinkerDelta
    ),
    plannedYearlyCementProductionVolume: convertFromTonsToKilotons(
      descendant.plannedYearlyProductionVolume.plannedCementProduction
    ),
    plannedYearlyClinkerProductionVolume: convertFromTonsToKilotons(
      descendant.plannedYearlyProductionVolume.plannedClinkerProduction
    )
  }))

export const mapProductionPerformanceValuesDto = (
  data: ProductionPerformanceValuesResponse
): ProductionPerformanceValues & PerformanceData => ({
  ...data,
  descendants: mapProductionPerformanceValuesDescendantsDto(data.descendants),
  historicalData: orderBy(
    data.historicalData.map(mapProductionHistoricalDataDto),
    'yearOffset',
    'desc'
  ),
  unit: UNITS[KpisList.ClinkerProduction],
  plannedCementProduction: convertFromTonsToKilotons(data.plannedCementProduction),
  plannedClinkerProduction: convertFromTonsToKilotons(data.plannedClinkerProduction),
  actualCementProduction: convertFromTonsToKilotons(data.actualCementProduction),
  actualClinkerProduction: convertFromTonsToKilotons(data.actualClinkerProduction),
  cementDelta: extractDelta(
    data.plannedCementProduction,
    data.actualCementProduction,
    data.cementDelta
  ),
  clinkerDelta: extractDelta(
    data.plannedClinkerProduction,
    data.actualClinkerProduction,
    data.clinkerDelta
  )
})

export const mapClinkerProductionQuarterlyDataDto = (
  data: QuarterlyForecastClinkerProductionHierarchy
): QuarterlyForecastClinkerProductionHierarchy => ({
  ...data,
  quarterlyForecastClinkerProduction: convertFromTonsToKilotons(
    data.quarterlyForecastClinkerProduction || 0
  ),
  descendants: data.descendants.map(mapClinkerProductionQuarterlyDataDto)
})

export const mapCementProductionQuarterlyDataDto = (
  data: QuarterlyForecastCementProductionHierarchy
): QuarterlyForecastCementProductionHierarchy => ({
  ...data,
  quarterlyForecastCementProduction: convertFromTonsToKilotons(
    data.quarterlyForecastCementProduction || 0
  ),
  descendants: data.descendants.map(mapCementProductionQuarterlyDataDto)
})

const mapKilnCoefficientsDescendantsDto = (
  data: KilnCoefficientsResponse[],
  yearData?: PlannedYearlyKilnCoefficientHierarchy[],
  parentCountryCode?: string
): PerformanceDescendantWithId<KilnCoefficients>[] =>
  data.map((descendant, index) => {
    const id = descendant.location?.id || descendant.plantId || `kiln-coefficient-${index}`
    const descendantYearData = yearData?.find(({location, plantId}) => {
      const locationId = location?.id || plantId
      return locationId === id
    })

    return {
      ...descendant,
      id,
      locationData: descendant.plantId
        ? {
            id: descendant.plantId,
            type: 'plant',
            name: plants[descendant.plantId]?.name || descendant.plantId,
            countryCode: parentCountryCode,
            path: descendant.location?.path
          }
        : descendant.location,
      unit: UNITS[KpisList.KilnReliabilityCoefficient],
      operatingDelta: extractDelta(
        descendant.plannedOperatingCoefficient,
        descendant.actualOperatingCoefficient,
        descendant.operatingDelta
      ),
      reliabilityDelta: extractDelta(
        descendant.plannedReliabilityCoefficient,
        descendant.actualReliabilityCoefficient,
        descendant.reliabilityDelta
      ),
      historicalData: descendant.historicalData,
      subItems: mapKilnCoefficientsDescendantsDto(
        descendant.descendants,
        descendantYearData?.descendants
      ),
      actualOperatingCoefficient: getRoundedToDecimal(descendant.actualOperatingCoefficient, 1),
      actualReliabilityCoefficient: getRoundedToDecimal(descendant.actualReliabilityCoefficient, 1),
      plannedOperatingCoefficient: getRoundedToDecimal(descendant.plannedOperatingCoefficient, 1),
      plannedReliabilityCoefficient: getRoundedToDecimal(
        descendant.plannedReliabilityCoefficient,
        1
      ),
      descendants: mapKilnCoefficientsDescendantsDto(
        descendant.descendants,
        descendantYearData?.descendants
      ),
      plannedYearlyOperatingCoefficient: getRoundedToDecimal(
        descendantYearData?.plannedOperatingCoefficient || 0,
        1
      ),
      plannedYearlyReliabilityCoefficient: getRoundedToDecimal(
        descendantYearData?.plannedReliabilityCoefficient || 0,
        1
      )
    }
  })

export const mapKilnCoefficientsDto = (
  data: KilnCoefficientsResponse,
  yearData?: PlannedYearlyKilnCoefficientHierarchy
): KilnCoefficients => ({
  ...data,
  locationData: data.location,
  descendants: mapKilnCoefficientsDescendantsDto(
    data.descendants,
    yearData?.descendants,
    data.location?.countryCode
  ),
  historicalData: orderBy(data.historicalData, 'yearOffset', 'desc'),
  unit: UNITS[KpisList.KilnReliabilityCoefficient],
  plannedReliabilityCoefficient: getRoundedToDecimal(
    yearData?.plannedReliabilityCoefficient || 0,
    1
  ),
  plannedOperatingCoefficient: getRoundedToDecimal(yearData?.plannedOperatingCoefficient || 0, 1),
  operatingDelta: extractDelta(
    data.plannedOperatingCoefficient,
    data.actualOperatingCoefficient,
    data.operatingDelta
  ),
  reliabilityDelta: extractDelta(
    data.plannedReliabilityCoefficient,
    data.actualReliabilityCoefficient,
    data.reliabilityDelta
  )
})

const mapKilnHeatConsumptionsDescendantsDto = (
  data: KilnHeatConsumptionsResponse[],
  yearData?: PlannedYearlyKilnHeatConsumptionsHierarchy[],
  parentCountryCode?: string
): PerformanceDescendantWithId<KilnHeatConsumptions>[] => {
  return data.map((descendant, index) => {
    const id = descendant.location?.id || descendant.plantId || `kiln-heat-consumptions-${index}`
    const descendantYearData = yearData?.find(({location, plantId}) => {
      const locationId = location?.id || plantId
      return locationId === id
    })

    return {
      ...descendant,
      id,
      locationData: descendant.plantId
        ? {
            id: descendant.plantId,
            type: 'plant',
            name: plants[descendant.plantId]?.name || descendant.plantId,
            countryCode: parentCountryCode,
            path: descendant.location?.path
          }
        : descendant.location,
      unit: UNITS[KpisList.KilnHeatConsumption],
      historicalData: descendant.historicalData,
      subItems: mapKilnHeatConsumptionsDescendantsDto(
        descendant.descendants,
        descendantYearData?.descendants
      ),
      descendants: mapKilnHeatConsumptionsDescendantsDto(
        descendant.descendants,
        descendantYearData?.descendants
      ),
      actualKilnHeatConsumptions: getRoundedToDecimal(descendant.actualKilnHeatConsumptions, 2),
      plannedKilnHeatConsumptions: getRoundedToDecimal(descendant.plannedKilnHeatConsumptions, 2),
      plannedYearlyKilnHeatConsumptions: getRoundedToDecimal(
        descendantYearData?.plannedKilnHeatConsumptions || 0,
        2
      ),
      delta: extractDelta(
        descendant.plannedKilnHeatConsumptions,
        descendant.actualKilnHeatConsumptions,
        descendant.delta
      )
    }
  })
}

export const mapKilnHeatConsumptionsDto = (
  data: KilnHeatConsumptionsResponse,
  yearData?: PlannedYearlyKilnHeatConsumptionsHierarchy
): KilnHeatConsumptions => ({
  ...data,
  locationData: data.location,
  descendants: mapKilnHeatConsumptionsDescendantsDto(
    data.descendants,
    yearData?.descendants,
    data.location?.countryCode
  ),
  historicalData: orderBy(data.historicalData, 'yearOffset', 'desc'),
  unit: UNITS[KpisList.KilnHeatConsumption],
  plannedYearlyKilnHeatConsumptions: getRoundedToDecimal(
    yearData?.plannedKilnHeatConsumptions || 0,
    2
  ),
  delta: extractDelta(data.plannedKilnHeatConsumptions, data.actualKilnHeatConsumptions, data.delta)
})

const mapTechnicalCementPowerConsumptionDescendantsDto = (
  data: TechnicalCementPowerConsumptionResponse[],
  yearData?: PlannedYearlyTechnicalCementPowerConsumptionsHierarchy[],
  parentCountryCode?: string
): PerformanceDescendantWithId<TechnicalCementPowerConsumption>[] =>
  data.map((descendant, index) => {
    const id =
      descendant.location?.id || descendant.plantId || `technical-cement-power-consumption-${index}`
    const descendantYearData = yearData?.find(({location, plantId}) => {
      const locationId = location?.id || plantId
      return locationId === id
    })

    return {
      ...descendant,
      id,
      locationData: descendant.plantId
        ? {
            id: descendant.plantId,
            type: 'plant',
            name: plants[descendant.plantId]?.name || descendant.plantId,
            countryCode: parentCountryCode,
            path: descendant.location?.path
          }
        : descendant.location,
      unit: UNITS[KpisList.TechnicalCementPowerCons],
      delta: extractDelta(
        descendant.plannedTechnicalCementPowerConsumptions,
        descendant.actualTechnicalCementPowerConsumptions,
        descendant.delta
      ),
      historicalData: descendant.historicalData,
      actualTechnicalCementPowerConsumptions: getRoundedToDecimal(
        descendant.actualTechnicalCementPowerConsumptions,
        2
      ),
      plannedTechnicalCementPowerConsumptions: getRoundedToDecimal(
        descendant.plannedTechnicalCementPowerConsumptions,
        2
      ),
      subItems: mapTechnicalCementPowerConsumptionDescendantsDto(
        descendant.descendants,
        descendantYearData?.descendants
      ),
      descendants: mapTechnicalCementPowerConsumptionDescendantsDto(
        descendant.descendants,
        descendantYearData?.descendants
      ),
      plannedYearlyTechnicalCementPowerConsumptions: getRoundedToDecimal(
        descendantYearData?.plannedTechnicalCementPowerConsumptions || 0,
        2
      )
    }
  })

export const mapTechnicalCementPowerConsumptionDto = (
  data: TechnicalCementPowerConsumptionResponse,
  yearData?: PlannedYearlyTechnicalCementPowerConsumptionsHierarchy
): TechnicalCementPowerConsumption => ({
  ...data,
  descendants: mapTechnicalCementPowerConsumptionDescendantsDto(
    data.descendants,
    yearData?.descendants,
    data.location?.countryCode
  ),
  historicalData: orderBy(data.historicalData, 'yearOffset', 'desc'),
  plannedYearlyTechnicalCementPowerConsumptions: getRoundedToDecimal(
    yearData?.plannedTechnicalCementPowerConsumptions || 0,
    2
  ),
  locationData: data.location,
  unit: UNITS[KpisList.TechnicalCementPowerCons],
  delta: extractDelta(
    data.plannedTechnicalCementPowerConsumptions,
    data.actualTechnicalCementPowerConsumptions,
    data.delta
  )
})

const mapMeanTimeBetweenFailuresHistoricalDataDto = (
  data: HistoricalMeanTimeBetweenFailures
): HistoricalMeanTimeBetweenFailures => ({
  ...data,
  actualMeanTimeBetweenFailures: round(data.actualMeanTimeBetweenFailures),
  plannedMeanTimeBetweenFailures: round(data.plannedMeanTimeBetweenFailures)
})

const mapMeanTimeBetweenFailuresDescendantsDto = (
  data: MeanTimeBetweenFailuresResponse[],
  yearData?: PlannedYearlyMeanTimeBetweenFailuresHierarchy[],
  parentCountryCode?: string
): PerformanceDescendantWithId<MeanTimeBetweenFailures>[] =>
  data.map((descendant, index) => {
    const id =
      descendant.location?.id || descendant.plantId || `mean-time-between-failures-${index}`
    const descendantYearData = yearData?.find(({location, plantId}) => {
      const locationId = location?.id || plantId
      return locationId === id
    })
    return {
      ...descendant,
      id,
      locationData: descendant.plantId
        ? {
            id: descendant.plantId,
            name: plants[descendant.plantId]?.name || descendant.plantId,
            countryCode: parentCountryCode,
            path: descendant.location?.path,
            type: 'plant'
          }
        : descendant.location,
      unit: UNITS[KpisList.MTBF],
      delta: extractDelta(
        descendant.plannedMeanTimeBetweenFailures,
        descendant.actualMeanTimeBetweenFailures,
        descendant.delta
      ),
      historicalData: descendant.historicalData.map(mapMeanTimeBetweenFailuresHistoricalDataDto),
      subItems: mapMeanTimeBetweenFailuresDescendantsDto(
        descendant.descendants,
        descendantYearData?.descendants
      ),
      descendants: mapMeanTimeBetweenFailuresDescendantsDto(
        descendant.descendants,
        descendantYearData?.descendants
      ),
      plannedMeanTimeBetweenFailures: round(descendant.plannedMeanTimeBetweenFailures),
      actualMeanTimeBetweenFailures: round(descendant.actualMeanTimeBetweenFailures),
      plannedYearlyProductionVolume: descendantYearData?.plannedMeanTimeBetweenFailures
    }
  })

export const mapMeanTimeBetweenFailuresDto = (
  data: MeanTimeBetweenFailuresResponse,
  yearData?: PlannedYearlyMeanTimeBetweenFailuresHierarchy
): MeanTimeBetweenFailures => ({
  ...data,
  descendants: mapMeanTimeBetweenFailuresDescendantsDto(
    data.descendants,
    yearData?.descendants,
    data.location?.countryCode
  ),
  locationData: data.location,
  historicalData: orderBy(
    data.historicalData.map(mapMeanTimeBetweenFailuresHistoricalDataDto),
    'yearOffset',
    'desc'
  ),
  unit: UNITS[KpisList.MTBF],
  plannedMeanTimeBetweenFailures: round(data.plannedMeanTimeBetweenFailures),
  actualMeanTimeBetweenFailures: round(data.actualMeanTimeBetweenFailures),
  delta: extractDelta(
    data.plannedMeanTimeBetweenFailures,
    data.actualMeanTimeBetweenFailures,
    data.delta
  )
})

const mapAlternativeFuelRatesHistoricalDataDto = (
  data: HistoricalAlternativeFuelRates
): HistoricalAlternativeFuelRates => ({
  ...data,
  actualAlternativeFuelRates: getRoundedToDecimal(data.actualAlternativeFuelRates, 1),
  plannedAlternativeFuelRates: getRoundedToDecimal(data.plannedAlternativeFuelRates, 1)
})

const mapAlternativeFuelRatesDescendantsDto = (
  data: AlternativeFuelRatesResponse[],
  yearData?: PlannedAlternativeFuelRatesHierarchy[],
  parentCountryCode?: string
): PerformanceDescendantWithId<AlternativeFuelRates>[] =>
  data.map((descendant) => {
    const id = descendant.location?.id || descendant.plantId
    const descendantYearData = yearData?.find(({location, plantId}) => {
      const locationId = location?.id || plantId
      return locationId === id
    })

    return {
      ...mapAlternativeFuelRatesDto(descendant, descendantYearData),
      id,
      subItems: mapAlternativeFuelRatesDescendantsDto(
        descendant.descendants,
        descendantYearData?.descendants,
        parentCountryCode
      ),
      locationData: descendant.plantId
        ? ({
            id: descendant.plantId,
            name: plants[descendant.plantId]?.name || descendant.plantId,
            countryCode: parentCountryCode
          } as Location)
        : descendant.location
    }
  })

export const mapAlternativeFuelRatesDto = (
  data: AlternativeFuelRatesResponse,
  yearData?: PlannedAlternativeFuelRatesHierarchy
): AlternativeFuelRates => {
  return {
    ...data,
    descendants: mapAlternativeFuelRatesDescendantsDto(
      data.descendants,
      yearData?.descendants,
      data.location?.countryCode
    ),
    historicalData: orderBy(
      data.historicalData.map(mapAlternativeFuelRatesHistoricalDataDto),
      'yearOffset',
      'desc'
    ),
    locationData: data.location,
    unit: UNITS[KpisList.AlternativeFuelRate],
    plannedAlternativeFuelRates: getRoundedToDecimal(data.plannedAlternativeFuelRates, 1),
    actualAlternativeFuelRates: getRoundedToDecimal(data.actualAlternativeFuelRates, 1),
    plannedYearlyProductionVolume: getRoundedToDecimal(
      yearData?.plannedAlternativeFuelRates || 0,
      1
    ),
    delta: extractDelta(
      data.plannedAlternativeFuelRates,
      data.actualAlternativeFuelRates,
      data.delta
    )
  }
}

const createMapUtil = (node, map) => {
  if (node) map.set(node.locationId, node)
  if (node?.descendants) {
    node.descendants.forEach((descendant) => {
      createMapUtil(descendant, map)
    })
  }
}

const createQuarterlyDataMap = (
  kpiQuarterlyHierarchy?: KpiQuarterlyData
): Map<string, KpiQuarterlyData> => {
  const quarterlyList = new Map()
  createMapUtil(kpiQuarterlyHierarchy, quarterlyList)
  return quarterlyList
}

const mapKpiDataToAggregatedKpiData = (
  map: Map<string, KpiQuarterlyData>,
  currentTab: KpisList,
  data?: KpiData
): AggregatedKpiData => {
  const quarterlyData = map.get(data?.locationData?.id || '')

  const restData = omit(data, ['descendants', 'subItems'])

  const aggregatedData: AggregatedKpiData = {
    ...restData,
    quarterlyForecast: {
      quarterlyForecastValue: quarterlyData?.[TabIndexDataMap[currentTab].quarterlyForecast],
      delta: quarterlyData?.delta
    },
    descendants: data?.descendants.map((descendant: KpiData) =>
      mapKpiDataToAggregatedKpiData(map, currentTab, descendant)
    ),
    ...(data?.['subItems'] && {
      subItems: data['subItems'].map((subItem: KpiData) =>
        mapKpiDataToAggregatedKpiData(map, currentTab, subItem)
      )
    })
  }
  return aggregatedData
}

// function to recursively attach quarterly data to the kpi data
export const attachQuarterlyData = (
  currentTab: KpisList,
  data?: KpiData,
  kpiQuarterlyData?: KpiQuarterlyData
): AggregatedKpiData => {
  const quarterlyData = createQuarterlyDataMap(kpiQuarterlyData)
  const aggregatedData: AggregatedKpiData = mapKpiDataToAggregatedKpiData(
    quarterlyData,
    currentTab,
    data
  )
  return aggregatedData
}
