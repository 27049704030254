export const ApiPath = {
  HROC_CHECKLISTS: '/hroc-checklists/api',
  KMS: '/kms',
  KPI: '/kpi',
  KPI_RESULT: '/kpi-result',
  POM_QUESTIONNARIES: '/pom-questionnaires',
  SHIFT_HANDOVER: '/shifthandover',
  RUNNING_TIMES_ORCHESTRATOR: '/runningtimes-orchestrator',
  TASKS: '/tasks',
  WORK_ORDERS: '/work-orders'
}
