import {FilterMultiSelect, FilterMultiSelectProps} from '../../../shared/components'
import {FILTER_VALUE_SEPARATOR} from '../../../shared/consts'
import {usePomSections} from '../../hooks'

export const PomSectionFilter = <TName extends string>(
  props: Omit<FilterMultiSelectProps<string, TName>, 'options'>
) => {
  const {data: options = []} = usePomSections({
    select: (sections) => sections.map(({sectionId, name}) => ({value: sectionId, label: name}))
  })
  const optionsMap = new Map(options.map(({value, label}) => [value, label]))

  return (
    <FilterMultiSelect
      {...props}
      options={options}
      renderValue={(value) => {
        return value.map((section) => optionsMap.get(section) ?? '').join(FILTER_VALUE_SEPARATOR)
      }}
    />
  )
}
