import {ArrowBack, ArrowForward, Check, Close} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'
import {Button, Stack, useMediaQuery, useTheme} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {UseStepActions} from '../../shared/hooks/useStep'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'

type Props = {
  stepActions: UseStepActions
  onCancel?: () => void
  onGoToPrevStep?: () => void
  isValid?: boolean
  isSubmitting?: boolean
  readOnly?: boolean
  backToTargetLabel: string
}

export const StepFormActions = ({
  stepActions,
  onCancel,
  onGoToPrevStep,
  isValid,
  isSubmitting,
  readOnly,
  backToTargetLabel
}: Props) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const disabledSubmit = !isValid || isSubmitting
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Stack
      direction={isSmall ? 'column-reverse' : 'row'}
      flexWrap="wrap"
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
      useFlexGap
      width="100%"
    >
      {stepActions.canGoToPrevStep ? (
        <Button onClick={onGoToPrevStep} startIcon={<ArrowBack />} fullWidth={isSmall}>
          {t(`${hrocPrefix}.button.previous`)}
        </Button>
      ) : (
        <span style={{minWidth: 60}} />
      )}
      {stepActions.canGoToNextStep ? (
        <Stack
          direction={isSmall ? 'column-reverse' : 'row'}
          spacing={1}
          width={{xs: '100%', sm: 'auto'}}
        >
          {!stepActions.canGoToPrevStep && (
            <Button onClick={onCancel} startIcon={<Close />} fullWidth={isSmall}>
              {t(`${hrocPrefix}.button.cancel`)}
            </Button>
          )}
          <Button
            key="next-button"
            type="submit"
            variant="contained"
            endIcon={<ArrowForward />}
            fullWidth={isSmall}
            data-test-id="step-form-next-button"
          >
            {t(`${hrocPrefix}.button.next`)}
          </Button>
        </Stack>
      ) : (
        <>
          {readOnly ? (
            <Button
              key="back-to-checklist-details-button"
              variant="contained"
              endIcon={<ArrowForward />}
              fullWidth={isSmall}
              onClick={onCancel}
            >
              {backToTargetLabel}
            </Button>
          ) : (
            <LoadingButton
              key="submit-button"
              type="submit"
              variant="contained"
              startIcon={<Check />}
              loadingPosition="start"
              loading={isSubmitting}
              disabled={disabledSubmit}
              fullWidth={isSmall}
              data-test-id="step-form-send-button"
            >
              {t(`${hrocPrefix}.button.sendChecklist`)}
            </LoadingButton>
          )}
        </>
      )}
    </Stack>
  )
}
