import {formatNumber} from '@hconnect/common/utils'
import {Moment} from 'moment'

import {MonthlyAggregatedPlantProductionKpisDto} from '../types'

export const formatKpiValue = (value: string | undefined, language: string): string | undefined => {
  const parsedValue = parseFloat(value ?? '')

  return !isNaN(parsedValue) ? formatNumber(parsedValue, language) : undefined
}

export const getHasDenodoDataForDate = (
  plantProductionKpis: MonthlyAggregatedPlantProductionKpisDto[] | undefined,
  date: Moment
): boolean => {
  const year = date.year()
  // months are 0-indexed in moment.js so we need to add 1 to match API indexing
  const month = date.month() + 1

  return !!plantProductionKpis?.find((kpi) => kpi.year === year && kpi.month === month)
    ?.monthToMonthKpiValues
}
