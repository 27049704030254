import {Permission} from '@hconnect/apiclient'
import {Route} from 'react-router-dom'

import {checkFeatureFlag} from '../shared/helpers/featureFlagHelpers'
import {ConfigProviderWrapper} from '../shared/hooks/useConfig'
import {PermissionsContextProvider} from '../shared/hooks/usePermission'
import {TranslationPrefixProvider} from '../shared/hooks/useTranslationPrefix'
import {DefaultShell} from '../shared/layouts'
import {Context} from '../shared/types/temp.types'

import {UserContextProvider} from './hooks/useUserContext'
import {
  ChecklistsTemplatesPage,
  PlantChecklistPage,
  PlantSelectByPermission,
  ProcessEngineerDashboard,
  ReliabilityEngineerDashboard
} from './pages'
import {PlantPermissionGuardedRoute} from './routing'
import {
  CHECKLISTS,
  PLANT_CHECKLISTS_TEMPLATES,
  PLANT_CHECKLIST,
  RELIABILITY_ENGINEER_DASHBOARD,
  PLANT_RELIABILITY_ENGINEER_DASHBOARD,
  PROCESS_ENGINEER_DASHBOARD,
  PLANT_PROCESS_ENGINEER_DASHBOARD
} from './routing/routes'
import {
  hasChecklistsPermission,
  hasViewReliabilityEngineerDashboardPermission,
  hasViewProcessEngineerDashboardPermission
} from './utils/permissions'

const Wrapper: React.FC<{
  context: Context
  permissions: Permission[]
  children: React.ReactNode
}> = ({children, context, permissions}) => (
  <TranslationPrefixProvider>
    <UserContextProvider initialState={context.user}>
      <PermissionsContextProvider initialState={permissions}>
        <DefaultShell context={context}>{children}</DefaultShell>
      </PermissionsContextProvider>
    </UserContextProvider>
  </TranslationPrefixProvider>
)

export const getHrocRoutes = ({
  context,
  permissions
}: {
  context: Context
  permissions: Permission[]
}) => {
  const areChecklistsEnabled = checkFeatureFlag('checklists')

  return (
    <>
      {areChecklistsEnabled && hasChecklistsPermission(permissions) && (
        <>
          <Route
            key="checklists-plant-select"
            path={CHECKLISTS}
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantSelectByPermission
                  path={PLANT_CHECKLISTS_TEMPLATES}
                  permission="VIEW_CHECKLISTS"
                />
              </Wrapper>
            }
          />
          <Route
            path={PLANT_CHECKLISTS_TEMPLATES}
            key="checklists-templates"
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantPermissionGuardedRoute
                  permission="VIEW_CHECKLISTS"
                  plantSelectPath={CHECKLISTS}
                >
                  <ConfigProviderWrapper>
                    <ChecklistsTemplatesPage />
                  </ConfigProviderWrapper>
                </PlantPermissionGuardedRoute>
              </Wrapper>
            }
          />
          <Route
            path={PLANT_CHECKLIST}
            key="plant-checklist"
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantChecklistPage />
              </Wrapper>
            }
          />
        </>
      )}
      {hasViewProcessEngineerDashboardPermission(permissions) && (
        <>
          <Route
            key="process-engineer-dashboard-plant-select"
            path={PROCESS_ENGINEER_DASHBOARD}
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantSelectByPermission
                  path={PLANT_PROCESS_ENGINEER_DASHBOARD}
                  permission="VIEW_PROCESS_ENGINEER_DASHBOARD"
                />
              </Wrapper>
            }
          />
          <Route
            path={PLANT_PROCESS_ENGINEER_DASHBOARD}
            key="process-engineer-dashboard"
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantPermissionGuardedRoute
                  permission="VIEW_PROCESS_ENGINEER_DASHBOARD"
                  plantSelectPath={PROCESS_ENGINEER_DASHBOARD}
                >
                  <ConfigProviderWrapper>
                    <ProcessEngineerDashboard />
                  </ConfigProviderWrapper>
                </PlantPermissionGuardedRoute>
              </Wrapper>
            }
          />
        </>
      )}

      {hasViewReliabilityEngineerDashboardPermission(permissions) && (
        <>
          <Route
            key="reliability-engineer-dashboard-plant-select"
            path={RELIABILITY_ENGINEER_DASHBOARD}
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantSelectByPermission
                  path={PLANT_RELIABILITY_ENGINEER_DASHBOARD}
                  permission="VIEW_RELIABILITY_ENGINEER_DASHBOARD"
                />
              </Wrapper>
            }
          />
          <Route
            path={PLANT_RELIABILITY_ENGINEER_DASHBOARD}
            key="reliability-engineer-dashboard"
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantPermissionGuardedRoute
                  permission="VIEW_RELIABILITY_ENGINEER_DASHBOARD"
                  plantSelectPath={RELIABILITY_ENGINEER_DASHBOARD}
                >
                  <ConfigProviderWrapper>
                    <ReliabilityEngineerDashboard />
                  </ConfigProviderWrapper>
                </PlantPermissionGuardedRoute>
              </Wrapper>
            }
          />
        </>
      )}
    </>
  )
}
