import {AxiosError} from 'axios'
import qs from 'qs'
import {useInfiniteQuery, UseInfiniteQueryOptions} from 'react-query'

import {ApiPath} from '../../shared/consts'
import {useAxios} from '../../shared/hooks/useApi'
import {mapTasksFiltersParams} from '../mappers'
import {PaginationResponse, HProduceTask} from '../types'

import {TasksParams} from './useTasks'

const TasksQueryKey = 'tasks'

export const useTasksInfiniteQuery = (
  params: TasksParams,
  options?: UseInfiniteQueryOptions<PaginationResponse<HProduceTask>, AxiosError, HProduceTask[]>
) => {
  const axiosInstance = useAxios()
  const {limit, sources, assignedToMe, filters, sorting, timezone} = params

  return useInfiniteQuery<PaginationResponse<HProduceTask>, AxiosError, HProduceTask[]>({
    queryKey: [TasksQueryKey, limit, sources, assignedToMe, filters, sorting],
    queryFn: async ({pageParam: offset = 0}) => {
      const response = await axiosInstance.post<PaginationResponse<HProduceTask>>(
        ApiPath.TASKS,
        mapTasksFiltersParams(timezone, filters),
        {
          params: {
            offset,
            limit,
            sources,
            includeOnlyAssignedToUser: assignedToMe,
            sortBy: sorting.sortBy,
            sortOrder: sorting.sortOrder
          },
          paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})
        }
      )

      return response.data ?? []
    },
    getNextPageParam: (lastPage, pages) => {
      // in infinite query this will determine the offset for the next page as 'pageParam' prop in the queryFn,
      // and if it returns false, it means there are no more pages (hasNextPage property will be false)
      return lastPage.items.length === limit ? pages.length * limit : false
    },
    select: (data) => ({...data, pages: data.pages.map((page) => page.items)}),
    ...options
  })
}
