import {MenuItemTooltipTitle} from '@hconnect/common/components'
import {getTranslationKey} from '@hconnect/common/utils'
import {useTranslation} from 'react-i18next'

export const PlantRequiredTooltip = ({
  translationPrefix,
  disabledFilter
}: {
  translationPrefix: string
  disabledFilter: string
}) => {
  const {t} = useTranslation()
  return (
    <MenuItemTooltipTitle
      title={t(getTranslationKey('filters.filterUnavailable', translationPrefix))}
      message={t(getTranslationKey('filters.filterDependencyMessage', translationPrefix), {
        disabledFilter,
        requiredFilter: t(getTranslationKey('tasksTable.label.plant', translationPrefix))
      })}
    />
  )
}
