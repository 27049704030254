import {QuestionDto, SectionDto, TopicDto} from '@hconnect/common/types'
import {getFilteredQuestions} from '@hconnect/common/utils'

import {FilterMultiSelect, FilterMultiSelectProps} from '../../../shared/components'
import {usePomSections} from '../../hooks'

const mapSection = ({sectionId, topics}: SectionDto) => ({
  id: sectionId,
  topics: topics.map(mapTopic)
})
const mapTopic = ({topicId, questions}: TopicDto) => ({id: topicId, questions})

type Topic = {id: string; questions?: QuestionDto[]}

const getTopicsOptions = (
  sections: SectionDto[],
  selectedSectionIds?: string[],
  selectedTopicIds?: string[]
) =>
  getFilteredQuestions<QuestionDto, Topic, {id: string; topics: Topic[]}>(
    sections.map(mapSection),
    selectedSectionIds,
    selectedTopicIds
  ).map(({id}) => ({
    value: id,
    label: id
  }))

export const PomQuestionFilter = <TName extends string>(
  props: Omit<FilterMultiSelectProps<string, TName>, 'options'> & {
    selectedSectionIds?: string[]
    selectedTopicIds?: string[]
  }
) => {
  const {data: options = []} = usePomSections({
    select: (sections) =>
      getTopicsOptions(sections, props.selectedSectionIds, props.selectedTopicIds)
  })

  return <FilterMultiSelect {...props} options={options} />
}
