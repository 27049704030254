import {COCKPIT_EVENTS_PAGE_TAB_PARAM_KEY} from '@hconnect/common/consts'
import {appUrl} from '@hconnect/common/hproduce/config'
import {COCKPIT_HOST, PLANT_EVENTS} from '@hconnect/common/hproduce/config/cockpit'
import {EventsTab as CockpitEventsTab} from '@hconnect/common/types'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {CalendarTodayOutlined} from '@mui/icons-material'
import {Box, Chip, SxProps, Theme, Typography} from '@mui/material'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {generatePath} from 'react-router'

import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {
  CardLinkWrapper,
  DataContentWrapper,
  WorkOrdersSummaryChart,
  WorkOrdersSummary
} from '../components'
import {useWorkOrdersSummary} from '../hooks/api'

type Props = {
  plantId: string
  variant?: 'chart' | 'list'
  cardBoxSx?: SxProps<Theme>
}

const generateCockpitWorkOrdersLink = ({plantId}: {plantId: string}) =>
  `${appUrl(COCKPIT_HOST)}${generatePath(PLANT_EVENTS, {
    plantId
  })}?${COCKPIT_EVENTS_PAGE_TAB_PARAM_KEY}=${CockpitEventsTab.WorkOrders}`

export const WorkOrdersSummaryContainer = ({plantId, cardBoxSx, variant = 'chart'}: Props) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const timezone = usePlantTimezone()
  const startOfToday = moment.utc().tz(timezone).startOf('day')
  const thirtyDaysAgo = startOfToday.clone().subtract(30, 'day')
  const endOfToday = startOfToday.clone().endOf('day')

  const {data, isLoading, isError, isRefetching, refetch} = useWorkOrdersSummary({
    plantId,
    from: thirtyDaysAgo,
    to: endOfToday
  })

  const linkPath = generateCockpitWorkOrdersLink({plantId})

  return (
    <CardLinkWrapper href={linkPath} data-test-id="work-order-summary-card-link-to-cockpit">
      <CardBox data-test-id="work-order-summary-card" sx={cardBoxSx}>
        <Box display="flex" flexDirection="row">
          <Box flex={1}>
            <Typography variant="h3" mb={5}>
              {t(`${hrocPrefix}.workOrderSummary.cardTitle`)}
            </Typography>
          </Box>
          <Chip
            icon={<CalendarTodayOutlined />}
            label={t(`${hrocPrefix}.label.last30days`)}
            size="small"
            sx={{mx: 1}}
          />
        </Box>
        <DataContentWrapper
          isLoading={isLoading}
          isError={isError}
          isRefetching={isRefetching}
          data={data}
          retryFunction={() => void refetch()}
          progressSx={{color: 'primary.main'}}
        >
          {variant === 'list' ? (
            <WorkOrdersSummary data={data} />
          ) : (
            <WorkOrdersSummaryChart data={data} />
          )}
        </DataContentWrapper>
      </CardBox>
    </CardLinkWrapper>
  )
}
