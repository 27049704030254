import {EquipmentRunningTimesResult} from '@hconnect/common/components/kpiPerformance/EquipmentRunningTimesResult'
import {mapRunningTimeDto} from '@hconnect/common/mappers'
import {EquipmentData, Iso8601, RunningTime, EquipmentRunningTimesDto} from '@hconnect/common/types'
import {get, last} from 'lodash'
import moment from 'moment'
import React, {useCallback} from 'react'

import {StackedBarChart} from '../../shared/components/charts'
import {useConfigData} from '../../shared/hooks/useConfig'
import {mapEquipmentRunningTimes} from '../../shared/mappers'

type Props = {
  runningTimePerformances?: EquipmentRunningTimesDto[] | undefined
  translationPrefix?: string
  from: Iso8601
  to: Iso8601
  timezone: string
  onEquipmentClick: (equipmentId: string) => void
}

export const OperationHoursList: React.FC<Props> = React.memo(
  ({runningTimePerformances, translationPrefix, from, to, timezone, onEquipmentClick}) => {
    const config = useConfigData()

    const getStackedBarChartComponent = useCallback(
      (equipment: EquipmentData, runningTimes: RunningTime[]) => {
        return (
          <StackedBarChart
            timeRange={{
              startDate: moment(from),
              endDate: moment(to)
            }}
            timezone={timezone}
            data={runningTimes.map(mapRunningTimeDto)}
            equipment={equipment}
            config={config}
          />
        )
      },
      [config, from, to, timezone]
    )

    return (
      <>
        {runningTimePerformances?.map((equipmentPerformanceData) => {
          const activeStatus = get(last(equipmentPerformanceData.runningTimes), 'runningTimeType')

          return (
            <EquipmentRunningTimesResult
              translationPrefix={translationPrefix}
              runningTimePerformance={mapEquipmentRunningTimes([equipmentPerformanceData])[0]}
              equipment={equipmentPerformanceData.equipment}
              key={equipmentPerformanceData.equipment.id}
              getStackedBarChartComponent={getStackedBarChartComponent}
              activeStatus={activeStatus}
              onClick={() => onEquipmentClick(equipmentPerformanceData.equipment.id)}
              hideDivider
            />
          )
        })}
      </>
    )
  }
)
