import {MenuItemTooltipTitle} from '@hconnect/common/components'
import {getTranslationKey} from '@hconnect/common/utils'
import {useTranslation} from 'react-i18next'

export const FilterIncompatibleTooltip = ({translationPrefix}: {translationPrefix: string}) => {
  const {t} = useTranslation()
  return (
    <MenuItemTooltipTitle
      title={t(getTranslationKey('filters.filterUnavailable', translationPrefix))}
      message={t(getTranslationKey('filters.filterIncompatibleMessage', translationPrefix))}
    />
  )
}
