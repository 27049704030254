import {type AppName} from '@hconnect/common/hproduce'
import {appUrl, hproduce} from '@hconnect/common/hproduce/config'
import {COCKPIT_HOST} from '@hconnect/common/hproduce/config/cockpit'
import {EnvUrls} from '@hconnect/common/hproduce/types'
import {generatePath} from 'react-router'

import {
  PROCESS_ENGINEER_PLANT_KPI,
  PROCESS_ENGINEER_STOPPAGE_ANALYSIS,
  RELIABILITY_ENGINEER_PLANT_KPI,
  RELIABILITY_ENGINEER_STOPPAGE_ANALYSIS
} from '../../shared/routes'
import {QuickSelectionId} from '../../shared/types'

const supportedAppHosts: Partial<Record<AppName, EnvUrls>> = {
  Cockpit: COCKPIT_HOST,
  HProduce: hproduce.url
}

export const getChecklistPageRefererUrl = (
  refererAppName: AppName | null,
  refererPath: string | null
) => {
  if (!refererAppName || !refererPath) return null

  const appHostConfig = supportedAppHosts[refererAppName]

  if (!appHostConfig) return null

  const hostUrl = appUrl(appHostConfig)
  const linkPath = `${hostUrl}${refererPath}`

  return linkPath
}

const getPerformancePagePath = (basePath: string, plantId: string, timeRange: QuickSelectionId) => {
  const path = generatePath(basePath, {plantId})
  const query = new URLSearchParams({timeRange}).toString()

  return `${path}?${query}`
}

export const getReliabilityEngineerPerformancePagePath = (
  plantId: string,
  timeRange: QuickSelectionId
) => {
  return getPerformancePagePath(RELIABILITY_ENGINEER_PLANT_KPI, plantId, timeRange)
}

export const getProcessEngineerPerformancePagePath = (
  plantId: string,
  timeRange: QuickSelectionId
) => {
  return getPerformancePagePath(PROCESS_ENGINEER_PLANT_KPI, plantId, timeRange)
}

export const getProcessEngineerStoppageAnalysisPagePath = (
  plantId: string,
  equipmentId: string
) => {
  return generatePath(PROCESS_ENGINEER_STOPPAGE_ANALYSIS, {plantId, equipmentId})
}

export const getReliabilityEngineerStoppageAnalysisPagePath = (
  plantId: string,
  equipmentId: string
) => {
  return generatePath(RELIABILITY_ENGINEER_STOPPAGE_ANALYSIS, {plantId, equipmentId})
}
