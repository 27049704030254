import {
  EquipmentRunningTimesDto as EquipmentRunningTimes,
  PlantRunningTimesDto as PlantRunningTimes
} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {stringify} from 'query-string'
import {useQuery, UseQueryOptions} from 'react-query'

import {ApiPath} from '../../shared/consts'
import {useAxios} from '../../shared/hooks/useApi'
import {TimeRange} from '../types'

const QueryKey = 'plant-running-times'

export const useRunningTimes = (
  timeRange: TimeRange,
  plantId: string,
  options?: UseQueryOptions<EquipmentRunningTimes[], AxiosError>
) => {
  const axiosInstance = useAxios()

  return useQuery<EquipmentRunningTimes[], AxiosError>(
    [QueryKey, timeRange, plantId],
    async () => {
      const response = await axiosInstance.get<PlantRunningTimes[]>(
        `${ApiPath.RUNNING_TIMES_ORCHESTRATOR}/running-times`,
        {
          params: {from: timeRange.startDate, to: timeRange.endDate, plantIds: [plantId]},
          paramsSerializer: (params: string[]) => stringify(params, {arrayFormat: 'none'})
        }
      )
      return response.data[0]?.equipmentsWithRunningTimes || []
    },
    options
  )
}
