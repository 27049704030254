import {AxiosError} from 'axios'
import {Moment} from 'moment'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {ApiPath} from '../../../../shared/consts'
import {handleError} from '../../../../shared/helpers/errorHandling'
import {useAxios} from '../../../../shared/hooks/useApi'
import {MonthlyKpiSummaryDto} from '../../../types'

const QueryKey = 'equipment-performances-monthly'

type MonthlyAggregatedKpisParams = {
  plantId: string
  from: Moment
  to: Moment
}

export const useEquipmentPerformancesMonthly = <TSelect = MonthlyKpiSummaryDto[]>(
  {plantId, ...searchParams}: MonthlyAggregatedKpisParams,
  options?: UseQueryOptions<MonthlyKpiSummaryDto[], AxiosError, TSelect>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const {from, to} = searchParams
  const fromYear = from.year()
  const toYear = to.year()
  // months are 0-indexed in moment.js so we need to add 1 for the API
  const fromMonth = from.month() + 1
  const toMonth = to.month() + 1

  // cannot be passed in params because axios is serializing this not in the way API expects
  const query = `?from.year=${fromYear}&from.month=${fromMonth}&to.year=${toYear}&to.month=${toMonth}`

  return useQuery<MonthlyKpiSummaryDto[], AxiosError, TSelect>({
    queryKey: [QueryKey, plantId, from.toISOString(), to.toISOString()],
    queryFn: async () => {
      const path = `${ApiPath.KPI_RESULT}/${plantId}/equipment-performances/monthly`
      const response = await axiosInstance.get<MonthlyKpiSummaryDto[]>(path + query)

      return response.data
    },
    onError: (err) => handleError(err, navigate),
    ...options
  })
}
