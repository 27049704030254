import {AddFilterButton} from '@hconnect/common/components'
import {getTranslationKey} from '@hconnect/common/utils'
import {TimeZone} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {TasksFilterKey, TasksFilterKeyName} from '../../consts'
import {type TasksFiltersState} from '../../types'
import {isTasksFilterKey} from '../../utils'

import {
  createCategoriesFilter,
  createDateRangeFilter,
  createEquipmentFilter,
  createMainEquipmentFilter,
  createPlantFilter,
  createPomActionSourceFilter,
  createPomQuestionCategoryFilter,
  createPomQuestionFilter,
  createPomSectionFilter,
  createPomTopicFilter,
  createPrioritiesFilter,
  createProcessStagesFilter,
  createStatusesFilter,
  createTextFilter,
  TaskFilterConfig,
  TasksFiltersStateValue
} from './taskFiltersConfig'

const plantRequiringFilters: TasksFilterKeyName[] = [
  TasksFilterKey.PROCESS_STAGES,
  TasksFilterKey.MAIN_EQUIPMENT,
  TasksFilterKey.EQUIPMENT
]

type Props = {
  translationPrefix: string
  filters: TasksFiltersState
  onFiltersChange: (filters: TasksFiltersState) => void
  timezone: TimeZone
}

export const TasksFilters: React.FC<Props> = ({
  translationPrefix,
  filters,
  onFiltersChange,
  timezone
}) => {
  const [visibleFilters, setVisibleFilters] = React.useState<TasksFilterKeyName[]>(
    getInitialVisibleFilters(filters)
  )
  const {t} = useTranslation()

  const handleFilterChange = (key: TasksFilterKeyName, value?: TasksFiltersStateValue) => {
    onFiltersChange({...filters, [key]: value})
  }

  const clearFilter = (key: TasksFilterKeyName) => {
    onFiltersChange({...filters, [key]: undefined})
    setVisibleFilters(visibleFilters.filter((filter) => filter !== key))
  }

  const createHandleFilterWithDependantFieldsChange =
    (filtersToClear?: TasksFilterKeyName[]) =>
    (key: TasksFilterKeyName, value?: TasksFiltersStateValue) => {
      const newFilters = {...filters}
      filtersToClear?.forEach((filter) => {
        newFilters[filter] = undefined
      })
      onFiltersChange({...newFilters, [key]: value})
    }

  const handlePlantFilterChange = createHandleFilterWithDependantFieldsChange(plantRequiringFilters)

  const handlePlantFilterClear = () => {
    handlePlantFilterChange(TasksFilterKey.PLANT, undefined)
    setVisibleFilters(
      visibleFilters
        .filter((filter) => filter !== TasksFilterKey.PLANT)
        .filter((filter) => !plantRequiringFilters.includes(filter))
    )
  }

  const handleVisibleFiltersChange = (value: TasksFilterKeyName[]) => {
    setVisibleFilters((prevFilters) => {
      const newVisibleFilters = !value.includes(TasksFilterKey.PLANT)
        ? value.filter((filter) => !plantRequiringFilters.includes(filter))
        : value
      const filtersToClear = prevFilters.filter((filter) => !newVisibleFilters.includes(filter))
      const newFilters = {...filters}

      filtersToClear.forEach((filter) => {
        newFilters[filter] = undefined
      })

      onFiltersChange(newFilters)

      return newVisibleFilters
    })
  }

  const filterBaseConfig = {
    filters,
    onChange: handleFilterChange,
    clearFilter,
    translationPrefix,
    visibleFilters
  }

  const filtersConfig: TaskFilterConfig[] = [
    createTextFilter({
      ...filterBaseConfig,
      key: TasksFilterKey.TITLE,
      label: t(getTranslationKey('shiftEvent.label.title', translationPrefix))
    }),
    createDateRangeFilter({
      ...filterBaseConfig,
      key: TasksFilterKey.DUE_DATE_RANGE,
      label: t(getTranslationKey('shiftEvent.label.dueDate', translationPrefix)),
      timezone
    }),
    createStatusesFilter({
      ...filterBaseConfig,
      key: TasksFilterKey.STATUSES,
      label: t(getTranslationKey('shiftEvent.label.status', translationPrefix))
    }),
    createCategoriesFilter({
      ...filterBaseConfig,
      key: TasksFilterKey.CATEGORIES,
      label: t(getTranslationKey('shiftEvent.label.category', translationPrefix))
    }),
    createPrioritiesFilter({
      ...filterBaseConfig,
      key: TasksFilterKey.PRIORITIES,
      label: t(getTranslationKey('shiftEvent.label.priority', translationPrefix))
    }),
    createPomActionSourceFilter({
      ...filterBaseConfig,
      key: TasksFilterKey.POM_ACTION_SOURCES,
      label: t(getTranslationKey('pomAction.label.source', translationPrefix))
    }),
    createPomSectionFilter({
      ...filterBaseConfig,
      key: TasksFilterKey.POM_SECTIONS,
      label: t(getTranslationKey('pomAction.label.section', translationPrefix)),
      onChange: createHandleFilterWithDependantFieldsChange([
        TasksFilterKey.POM_TOPICS,
        TasksFilterKey.POM_QUESTIONS
      ])
    }),
    createPomTopicFilter({
      ...filterBaseConfig,
      key: TasksFilterKey.POM_TOPICS,
      label: t(getTranslationKey('pomAction.label.topic', translationPrefix)),
      onChange: createHandleFilterWithDependantFieldsChange([TasksFilterKey.POM_QUESTIONS])
    }),
    createPomQuestionFilter({
      ...filterBaseConfig,
      key: TasksFilterKey.POM_QUESTIONS,
      label: t(getTranslationKey('pomAction.label.question', translationPrefix))
    }),
    createPomQuestionCategoryFilter({
      ...filterBaseConfig,
      key: TasksFilterKey.POM_QUESTION_CATEGORIES,
      label: t(getTranslationKey('pomAction.label.questionCategory', translationPrefix))
    }),
    createPlantFilter({
      ...filterBaseConfig,
      key: TasksFilterKey.PLANT,
      label: t(getTranslationKey('tasksTable.label.plant', translationPrefix)),
      onChange: handlePlantFilterChange,
      clearFilter: handlePlantFilterClear
    }),
    createProcessStagesFilter({
      ...filterBaseConfig,
      key: TasksFilterKey.PROCESS_STAGES,
      label: t(getTranslationKey('shiftEvent.label.processStage', translationPrefix))
    }),
    createMainEquipmentFilter({
      ...filterBaseConfig,
      key: TasksFilterKey.MAIN_EQUIPMENT,
      label: t(getTranslationKey('shiftEvent.label.mainEquipment', translationPrefix))
    }),
    createEquipmentFilter({
      ...filterBaseConfig,
      key: TasksFilterKey.EQUIPMENT,
      label: t(getTranslationKey('shiftEvent.label.equipment', translationPrefix))
    })
  ]

  return (
    <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
      {filtersConfig
        .filter((option) => visibleFilters.includes(option.value))
        .map((option) => (
          <React.Fragment key={option.value}>{option.filter}</React.Fragment>
        ))}
      <Box sx={{textWrap: 'nowrap'}}>
        <AddFilterButton
          data-test-id={'tasks-add-filter'}
          options={filtersConfig}
          onChange={handleVisibleFiltersChange}
          values={visibleFilters}
          buttonLabel={t(getTranslationKey('shiftEvent.label.addFilter', translationPrefix))}
        />
      </Box>
    </Box>
  )
}

const getInitialVisibleFilters = (filters: TasksFiltersState): TasksFilterKeyName[] => {
  return Object.keys(filters).filter(isTasksFilterKey)
}
