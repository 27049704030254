import {EquipmentRunningTimesDto, PlantRunningTimesDto} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {Moment} from 'moment'
import {stringify} from 'query-string'
import {useQuery, UseQueryOptions} from 'react-query'

import {ApiPath} from '../../../../shared/consts'
import {useAxios} from '../../../../shared/hooks/useApi'

const QueryKey = 'plant-running-times'

type UseOperationHoursParams = {
  plantId: string
  from: Moment
  to: Moment
}

export const useOperationHours = (
  {plantId, ...searchParams}: UseOperationHoursParams,
  options?: UseQueryOptions<EquipmentRunningTimesDto[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const fromDate = searchParams.from.toISOString()
  const toDate = searchParams.to.toISOString()

  return useQuery<EquipmentRunningTimesDto[], AxiosError>({
    queryKey: [QueryKey, plantId, fromDate, toDate],
    queryFn: async () => {
      const path = `${ApiPath.RUNNING_TIMES_ORCHESTRATOR}/running-times`
      const response = await axiosInstance.get<PlantRunningTimesDto[]>(path, {
        params: {from: fromDate, to: toDate, plantIds: [plantId]},
        paramsSerializer: (params: string[]) => stringify(params, {arrayFormat: 'none'})
      })

      return response.data[0]?.equipmentsWithRunningTimes || []
    },
    ...options
  })
}
